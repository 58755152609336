import EventHandler from '@/shared/components/state-selector/event/handlers/EventHandler';
import { Point } from '@/shared/components/state-selector/utils/math';

enum TimerStatus {
  PENDING,
  STARTED,
  STOPPED,
  TIMED_OUT,
}

class Timer {
  private startTime!: number;
  private animationId?: number;
  private status = TimerStatus.PENDING;

  constructor(private onProgress: (progress: number) => void, private duration: number) {
    this.update = this.update.bind(this);
  }

  start(): void {
    if (this.status !== TimerStatus.PENDING) {
      return;
    }

    this.animationId = requestAnimationFrame(this.update);
    this.status = TimerStatus.STARTED;
  }

  stop(): void {
    if (this.status !== TimerStatus.STARTED) {
      return;
    }

    if (this.animationId) {
      cancelAnimationFrame(this.animationId);

      this.animationId = undefined;
    }

    this.status = TimerStatus.STOPPED;
  }

  isTimedOut(): boolean {
    return this.status === TimerStatus.TIMED_OUT;
  }

  private update(timestamp: number): void {
    this.animationId = undefined;

    if (!this.startTime) {
      this.startTime = timestamp;
    }

    const progress = Math.min(timestamp - this.startTime, this.duration) / this.duration;

    this.onProgress(progress);

    if (progress < 1) {
      this.animationId = requestAnimationFrame(this.update);
    } else {
      this.status = TimerStatus.TIMED_OUT;
    }
  }
}

interface CountryHoldOptions {
  onProgress: (point: Point, progress: number) => void;
  onEnd: () => void;
}

export default class CountryHoldHandler extends EventHandler {
  private target?: SVGElement;
  private progressTimer?: Timer;
  private thresholdTimer?: number;

  constructor(private options: CountryHoldOptions) {
    super();

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
  }

  registerHook(): void {
    this.manager.root.addEventListener('mousedown', this.onMouseDown);
  }

  unregisterHook(): void {
    this.manager.root.addEventListener('mousedown', this.onMouseDown);

    this.clear();
  }

  private onMouseDown(event: MouseEvent): void {
    const target = (event.target as SVGElement)?.closest('.country') as SVGElement;

    if (!target) {
      return;
    }

    this.target = target;

    this.manager.root.addEventListener('mousemove', this.onMouseMove);
    this.manager.root.addEventListener('mouseup', this.onMouseUp);

    this.thresholdTimer = setTimeout(() => {
      if (!this.manager.isAllowed(event)) {
        this.clear();

        return;
      }

      this.progressTimer = new Timer((progress) => {
        this.options.onProgress({ x: event.clientX, y: event.clientY }, progress);
      }, 400);

      this.progressTimer.start();
    }, 150);
  }

  private onMouseMove(): void {
    this.clear();
  }

  private onMouseUp(event: MouseEvent): void {
    if (this.progressTimer?.isTimedOut()) {
      this.manager.record(event);

      this.target?.dispatchEvent(new CustomEvent('country-hold', { bubbles: true }));
    }

    this.clear();
  }

  private clear(): void {
    clearTimeout(this.thresholdTimer);

    this.progressTimer?.stop();
    this.progressTimer = undefined;

    this.manager.root.removeEventListener('mousemove', this.onMouseMove);
    this.manager.root.removeEventListener('mouseup', this.onMouseUp);

    this.options.onEnd();
  }
}
