export enum DirectorySortingType {
  ByNameAsc = '+name',
  ByNameDesc = '-name',
  ByCountryAsc = '+country',
  ByCountryDesc = '-country',
  ByCityAsc = '+city',
  ByCityDesc = '-city',
  ByPostalCodeAsc = '+zipCode',
  ByPostalCodeDesc = '-zipCode',
}
