import translator, { TranslatedMessage } from '@/shared/locale';

// @note Require to be int for RoutingMapFormOptions input range
export enum VehicleProfile {
  WEIGHT_3T5_OR_LESS = '1',
  AXLE_2_AND_WEIGHT_12T_OR_LESS = '2',
  AXLE_2_AND_WEIGHT_12T_OR_MORE = '3',
  AXLE_3 = '4',
  AXLE_3_OR_MORE = '5',
}

let labels: Record<VehicleProfile, TranslatedMessage>;

function loadEnum(): { labels: typeof labels } {
  if (!labels) {
    labels = {
      [VehicleProfile.WEIGHT_3T5_OR_LESS]: translator().t('< 3,5T'),
      [VehicleProfile.AXLE_2_AND_WEIGHT_12T_OR_LESS]: translator().t('2 essieux, < 12T'),
      [VehicleProfile.AXLE_2_AND_WEIGHT_12T_OR_MORE]: translator().t('2 essieux, 12T et > 12T'),
      [VehicleProfile.AXLE_3]: translator().t('3 essieux'),
      [VehicleProfile.AXLE_3_OR_MORE]: translator().t('> 3 essieux'),
    };
  }
  return { labels };
}

export function isVehicleProfile(code: unknown): code is VehicleProfile {
  return Object.values(VehicleProfile).includes(code as VehicleProfile);
}

export function list(): typeof labels {
  return loadEnum().labels;
}

export function label(code: VehicleProfile | string): TranslatedMessage | undefined {
  if (isVehicleProfile(code)) {
    return loadEnum().labels[code];
  }

  return undefined;
}
