import axios from './axios';

interface ApiExchange {
  key: string;
  value: string;
}

type EnumList = Record<string, string>;

let cache: EnumList;

export async function list(): Promise<EnumList> {
  if (cache) {
    return cache;
  }

  const { data } = await axios.get<ApiExchange[]>('enum/exchanges');
  const enumList: EnumList = data.reduce((list: EnumList, { key, value }: ApiExchange) => {
    list[key] = value;
    return list;
  }, {});

  return (cache = enumList);
}

export async function label(key: string): Promise<string | undefined> {
  return (await list())[key];
}
