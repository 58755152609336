import { computed, inject, onBeforeMount, provide, readonly, Ref, ref } from 'vue';

interface Tabs {
  current: Ref<string>;
  headers: Ref<Record<string, HTMLElement>>;
  tabs: Ref<string[]>;

  add(name: string): void;
}

const tabSymbol = Symbol();

export function useTabs(defaultTab: string) {
  const current = ref(defaultTab);
  const headers = ref<Record<string, HTMLElement>>({});
  const tabs = ref<string[]>([]);

  provide<Tabs>(tabSymbol, {
    current,
    headers,
    tabs,

    add: (tab: string) => {
      tabs.value = [...tabs.value, tab];
    },
  });

  return {
    current: readonly(current),
    headers: readonly(headers),
    tabs: readonly(tabs),

    registerHeader(tab: string, header: HTMLElement) {
      headers.value[tab] = header;
    },
    show(tab: string) {
      current.value = tab;
    },
  };
}

export function useTab(name: string) {
  const parent = inject<Tabs>(tabSymbol);

  if (!parent) {
    throw new Error('BaseTab should be inside a BaseTabs');
  }

  onBeforeMount(() => {
    parent.add(name);
  });

  return {
    header: computed(() => parent.headers.value[name]),
    isActive: computed(() => parent.current.value === name),
  };
}
