export default class Timer {
  private handle?: number;

  start(callback: () => void, timeout?: number): void {
    this.clear();

    this.handle = setTimeout(callback, timeout);
  }

  clear(): void {
    if (!this.handle) {
      return;
    }

    clearTimeout(this.handle);
    this.handle = undefined;
  }
}
