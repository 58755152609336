import { App, Directive } from 'vue';

function composeClassname(id: string): string {
  return 'ba-' + id;
}

export const TrackId: Directive = (el, binding) => {
  if (binding.oldValue && binding.value !== binding.oldValue) {
    el.classList.remove(composeClassname(binding.oldValue));
  }

  if (binding.value) {
    el.classList.add(composeClassname(binding.value));
  }
};

export const TrackIdPlugin = {
  install(app: App) {
    app.directive('track-id', TrackId);
  },
};
