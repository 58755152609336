import axios from 'axios';

import { setupAuthentication } from '@/shared/api/util/interceptors/setupAuthentication';
import { setupUserLanguage } from '@/shared/api/util/interceptors/setupUserLanguage';
import config from '@/shared/config';
import { AxiosRestClient } from '@/shared/dataProviders/common/AxiosRestClient';

import { EnumDataProvider } from '.';
import { B2PWebEnumDataProvider } from './b2pweb';

export * from './provider';
export * from './types';

let dataProvider: EnumDataProvider;

export function useEnumDataProvider(): EnumDataProvider {
  if (!dataProvider) {
    const client = axios.create({
      baseURL: config.urls.vehicleApi,
    });

    setupAuthentication(client);
    setupUserLanguage(client);

    dataProvider = new B2PWebEnumDataProvider(new AxiosRestClient(client));
  }

  return dataProvider;
}
