import { invert } from 'lodash-es';

import { Parameter as ApiParameter } from '@/shared/api/admin';
import { Parameter, ParameterMap, ParameterType } from '@/shared/dataProviders/admin/types';

const apiParameterIdMapping: { [key: ApiParameter['id']]: ParameterType } = {
  SHARED_OFFER: ParameterType.SHARED_OFFER,
  OFFER_DELETE_CAUSE: ParameterType.OFFER_DELETE_CAUSE,
};
const parameterIdMapping = invert(apiParameterIdMapping);

export function composeParameter<T extends ParameterType>(input: ApiParameter): Parameter<T> {
  return {
    id: apiParameterIdMapping[input.id] as T,
    value: input.value as ParameterMap[T],
  };
}

export function composeApiParameter(input: Parameter<ParameterType>): ApiParameter {
  return {
    id: parameterIdMapping[input.id],
    value: input.value,
  };
}
