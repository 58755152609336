
import { LIcon } from '@vue-leaflet/vue-leaflet';
import { computed, defineComponent, PropType } from 'vue';

export enum Variant {
  DEPARTURE,
  CROSSING,
  ARRIVAL,
}

const VARIANT_MAPS: Record<Variant, { container: string; content: string }> = {
  [Variant.DEPARTURE]: { container: 'bg-b2p-green-400 border-b2p-green-600', content: 'text-b2p-green-900' },
  [Variant.ARRIVAL]: { container: 'bg-red-400 border-red-600', content: 'text-red-900' },
  [Variant.CROSSING]: { container: 'bg-blue-400 border-blue-600', content: 'text-blue-900' },
};

export default defineComponent({
  components: {
    LIcon,
  },
  props: {
    size: { type: Number, default: 26 },
    variant: { type: Number as PropType<Variant>, required: true },
  },
  setup(props) {
    return {
      containerClass: computed(() => VARIANT_MAPS[props.variant].container),
      contentClass: computed(() => VARIANT_MAPS[props.variant].content),
      iconAnchor: computed(() => [props.size / 2, props.size + 4]),
      iconSize: computed(() => [props.size, props.size]),
    };
  },
});
