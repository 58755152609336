import { cancellable } from '@/shared/api/util/cancellable';

import client from './axios';

export async function getAuthorization(options?: { signal?: AbortSignal }): Promise<string> {
  const { data } = await cancellable(
    client.get<string>(`event-stream/authorization`, {
      signal: options?.signal,
    })
  );

  return data;
}
