import { get as localStorageGet, keys, set as localStorageSet } from '@/store/localStorage';

export const DEFAULT_COLOR = 'gray';
export const COLORS = [
  DEFAULT_COLOR,
  'green',
  'blue',
  'red',
  'yellow',
  'purple',
  'brown',
  'orange',
  'pink',
  'dark-blue',
  'dark-green',
];

export function provideSearchColor(id: string): string {
  const searchColors = localStorageGet<Record<string, string>>(keys.searchColors, {});

  if (!searchColors[id]) {
    searchColors[id] = DEFAULT_COLOR;
  }

  return COLORS.includes(searchColors[id]) ? searchColors[id] : DEFAULT_COLOR;
}

export function setSearchColor(id: string, color: string) {
  const searchColors = localStorageGet<Record<string, string>>(keys.searchColors, {});

  if (color !== searchColors[id]) {
    localStorageSet(keys.searchColors, {
      ...searchColors,
      [id]: color,
    });
  }
}

export function deleteSearchColor(id: string) {
  const searchColors = localStorageGet<Record<string, string>>(keys.searchColors, {});

  if (id in searchColors) {
    delete searchColors[id];
    localStorageSet(keys.searchColors, searchColors);
  }
}
