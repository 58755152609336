import httpClient, { isAxiosError } from '@/shared/api/admin/client';
import { Parameter, UserSettings } from '@/shared/api/admin/types';
import { FormValidationError, ParameterNotFoundError } from '@/shared/api/admin/utils';

export async function getParameter(id: string): Promise<Parameter> {
  try {
    const { data } = await httpClient.get<Parameter>(`customer/parameters/${id}`);

    return data;
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 404) {
      throw new ParameterNotFoundError(e.response.data.message);
    }

    throw e;
  }
}

export async function setParameter(data: Parameter) {
  try {
    await httpClient.put<Parameter>(`customer/parameters/${data.id}`, { value: data.value });
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400) {
      throw new FormValidationError('Error occurred while updating parameter', e.response.data.errors ?? []);
    }

    throw e;
  }
}

export async function getUserSettings(): Promise<UserSettings> {
  const { data } = await httpClient.get<UserSettings>(`user/settings`);

  return data;
}
