import { watch } from 'vue';

import config from '@/shared/config';
import { useAppStore } from '@/store/modules/app';

export default function () {
  if (!config.tracking.webSiteAccess) {
    return;
  }

  const appStore = useAppStore();

  watch(
    () => appStore.isLoggedIn,
    async (isLoggedIn) => {
      if (isLoggedIn && appStore.auth?.id) {
        const expireAt = new Date();
        expireAt.setMonth(expireAt.getMonth() + 12);
        document.cookie =
          `b2pweb_new_access=1;` +
          `expires=${expireAt};` +
          `domain=${config.tracking.webSiteAccessDomain};` +
          `path=${config.tracking.webSiteAccessPath}`;
      }
    },
    { immediate: true }
  );
}
