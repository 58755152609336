import axios from 'axios';

import { setupAuthentication } from '@/shared/api/util/interceptors/setupAuthentication';
import { setupUserLanguage } from '@/shared/api/util/interceptors/setupUserLanguage';
import config from '@/shared/config';

export const client = axios.create({
  baseURL: config.urls.directoryApi,
});

setupAuthentication(client);
setupUserLanguage(client);

export const isAxiosError = axios.isAxiosError;
