import { ref } from 'vue';

import { useResizeObserver } from '@/shared/composables/useResizeObserver';
import { RefElement } from '@/shared/composables/utils/unrefElement';
import { Scale } from '@/shared/types';

type ScaleObserverCallback = (contentRect: DOMRectReadOnly) => Scale;

export function useScaleObserver(target: RefElement, callback: ScaleObserverCallback) {
  const scale = ref<Scale>('xl');

  useResizeObserver(target, (entries: ResizeObserverEntry[]) => {
    scale.value = callback(entries[0].contentRect);
  });

  return {
    scale,
  };
}
