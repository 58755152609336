import EventHandler from '@/shared/components/state-selector/event/handlers/EventHandler';
import { Vector } from '@/shared/components/state-selector/utils/math';
import throttle from '@/shared/components/state-selector/utils/throttle';

interface DragOptions {
  onDragStart?: () => void;
  onDrag: (vector: Vector) => void;
  onDragEnd?: () => void;
}

const THRESHOLD = 10;

export default class DragHandler extends EventHandler {
  private dx!: number;
  private dy!: number;
  private enabled!: boolean;
  private target: EventTarget | null = null;

  constructor(private options: DragOptions) {
    super();

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.dispatchDragEvent = throttle(this.dispatchDragEvent.bind(this), 25);
  }

  registerHook(): void {
    this.manager.root.addEventListener('mousedown', this.onMouseDown);
  }

  unregisterHook(): void {
    this.manager.root.removeEventListener('mousedown', this.onMouseDown);

    this.clear();
  }

  private onMouseDown(event: MouseEvent): void {
    if (this.enabled) {
      this.clear();
    }

    if (!this.manager.isAllowed(event)) {
      return;
    }

    this.target = event.target;

    this.dx = 0;
    this.dy = 0;
    this.enabled = false;

    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
  }

  private onMouseMove(event: MouseEvent): void {
    event.preventDefault();

    // When the mouse cursor go outside the node, it's the only way to detect if the primary button was released
    if (event.buttons !== 1) {
      this.onMouseUp(event);

      return;
    }

    this.dx += event.movementX;
    this.dy += event.movementY;

    if (!this.enabled) {
      if (Math.abs(this.dx) < THRESHOLD && Math.abs(this.dy) < THRESHOLD) {
        return;
      }

      if (this.options.onDragStart) {
        this.options.onDragStart();
      }

      this.enabled = true;
    }

    this.dispatchDragEvent();
  }

  private onMouseUp(event: MouseEvent): void {
    try {
      if (this.enabled) {
        this.manager.record(event);

        if (this.options.onDragEnd) {
          this.options.onDragEnd();
        }
      }
    } finally {
      this.clear();
    }
  }

  private clear(): void {
    this.enabled = false;

    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  }

  private dispatchDragEvent(): void {
    const { dx, dy } = this;

    this.dx = 0;
    this.dy = 0;

    this.options.onDrag({ dx: -dx, dy: -dy });
  }
}
