import * as api from '@/shared/api/admin';
import { composeApiParameter, composeParameter } from '@/shared/dataProviders/admin/mappers';
import { Parameter, ParameterType, UserSettings } from '@/shared/dataProviders/admin/types';

export async function getParameter<T extends ParameterType>(id: T): Promise<Parameter<T> | undefined> {
  try {
    return composeParameter<T>(await api.getParameter(id));
  } catch (error) {
    if (error instanceof api.ParameterNotFoundError) {
      return;
    }
  }
}

export async function setParameter(input: Parameter<ParameterType>): Promise<void> {
  await api.setParameter(composeApiParameter(input));
}

export async function getUserSettings(): Promise<UserSettings> {
  return await api.getUserSettings();
}
