export class CircularBuffer<T> implements Iterable<T> {
  private readonly items: (T | undefined)[];
  private count = 0;
  private start = 0;

  constructor(private readonly capacity: number) {
    if (capacity < 1) {
      throw new Error('Cannot create a buffer with a capacity lower than 1');
    }

    this.items = new Array(capacity);
  }

  get length(): number {
    return this.count;
  }

  enqueue(item: T): void {
    this.items[(this.start + this.count) % this.capacity] = item;

    if (this.count === this.capacity) {
      this.moveToNext();
    } else {
      this.count += 1;
    }
  }

  dequeue(): T | undefined {
    if (!this.count) {
      return undefined;
    }

    const item = this.items[this.start];

    this.count -= 1;
    this.items[this.start] = undefined;

    this.moveToNext();

    return item;
  }

  forEach(callback: (item: T) => void): void {
    while (this.count > 0) {
      callback(this.dequeue() as T);
    }
  }

  *[Symbol.iterator]() {
    while (this.count > 0) {
      yield this.dequeue() as T;
    }
  }

  private moveToNext(): void {
    this.start = (this.start + 1) % this.capacity;
  }
}
