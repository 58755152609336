import { App, Directive } from 'vue';

import config from '@/shared/config';

export const TestId: Directive = {
  mounted: (el, binding) => {
    if (config.addTestSelectors) {
      el.setAttribute(`data-testid`, binding.value);
    }
  },
};

export const TestIdPlugin = {
  install(app: App): void {
    app.directive('test', TestId);
  },
};
