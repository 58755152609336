import { App } from 'vue';

import { HotkeysPlugin, RootChildPlugin, TestIdPlugin, TooltipPlugin, TrackIdPlugin } from '@/shared/directives';

export default (app: App) => {
  app.use(HotkeysPlugin);
  app.use(RootChildPlugin);
  app.use(TestIdPlugin);
  app.use(TooltipPlugin, {
    allowHTML: true,
    delay: [100, null],
    theme: 'light',
  });
  app.use(TrackIdPlugin);
};
