interface ValidationError {
  code: string;
  field: string;
  message: string;
}

export class ParameterNotFoundError extends Error {}

export class FormValidationError extends Error {
  public errors: ValidationError[] = [];

  constructor(message: string, errors: ValidationError[]) {
    super(message);
    this.errors = errors;
  }
}
