import { AbortOptions, Collection, PaginationOptions, SortingOptions } from '@/shared/dataProviders/common';
import {
  B2PWebCollection,
  B2PWebError,
  handleB2PWebResponse,
  mapToCollection,
} from '@/shared/dataProviders/common/b2pweb';
import { RestClient } from '@/shared/dataProviders/common/RestClient';
import {
  FetchVehiclesFilters,
  Vehicle,
  VehicleDataProvider,
  VehicleInput,
  VehicleTemplate,
} from '@/shared/dataProviders/vehicle';
import {
  B2PWebVehicle,
  B2PWebVehicleInput,
  B2PWebVehicleTemplate,
  mapToB2PWebVehicleInput,
  mapToVehicle,
  mapToVehicleTemplate,
} from '@/shared/dataProviders/vehicle/b2pweb';

export class B2PWebVehicleDataProvider implements VehicleDataProvider {
  constructor(private readonly client: RestClient) {}

  async create(input: VehicleInput, options?: AbortOptions): Promise<void> {
    return handleB2PWebResponse(
      await this.client.post<void, B2PWebVehicleInput, B2PWebError>('/vehicles', mapToB2PWebVehicleInput(input), {
        signal: options?.signal,
      })
    );
  }

  async update(id: string, input: VehicleInput, options?: AbortOptions): Promise<void> {
    return handleB2PWebResponse(
      await this.client.put<void, B2PWebVehicleInput, B2PWebError>(`/vehicles/${id}`, mapToB2PWebVehicleInput(input), {
        signal: options?.signal,
      })
    );
  }

  async fetchAll(
    filters?: FetchVehiclesFilters,
    options?: AbortOptions & PaginationOptions & SortingOptions
  ): Promise<Collection<Vehicle>> {
    const items = handleB2PWebResponse(
      await this.client.get<B2PWebCollection<B2PWebVehicle>, B2PWebError>('/vehicles', {
        params: {
          name: filters?.name,
          cityOrZone: filters?.location,
          availabilityDate: filters?.availabilityDate?.toISO({ suppressMilliseconds: true }),
          goodType: filters?.goodType ? Number(filters.goodType) : undefined,
          truckTypes: filters?.truckTypes ? filters.truckTypes.map((truckType) => Number(truckType)) : undefined,
          weight: filters?.weight ? Number(filters?.weight) : undefined,
          length: filters?.length ? Number(filters?.length) : undefined,
          volume: filters?.volume ? Number(filters?.volume) : undefined,
          fromCountry: filters?.departureCountry,
          fromZones: filters?.departureZones ? [filters.departureZones] : undefined,
          toCountry: filters?.arrivalCountry,
          toZones: filters?.arrivalZones ? [filters.arrivalZones] : undefined,
          exchanges: filters?.exchanges ? filters.exchanges : undefined,
          page: options?.page,
          per_page: options?.perPage,
          sort: options?.sort,
        },
        signal: options?.signal,
      })
    );
    return mapToCollection(items, mapToVehicle);
  }

  async fetch(id: string, options?: AbortOptions): Promise<Vehicle> {
    const item = handleB2PWebResponse(
      await this.client.get<B2PWebVehicle, B2PWebError>(`/vehicles/${id}`, {
        signal: options?.signal,
      })
    );
    return mapToVehicle(item);
  }

  async removeAll(ids: string[], options?: AbortOptions): Promise<void> {
    await Promise.all(ids.map((id) => this.remove(id, options)));
  }

  async remove(id: string, options?: AbortOptions): Promise<void> {
    return handleB2PWebResponse(
      await this.client.delete<void, B2PWebError>(`/vehicles/${id}`, {
        signal: options?.signal,
      })
    );
  }

  async fetchTemplates(options?: AbortOptions & PaginationOptions): Promise<Collection<VehicleTemplate>> {
    const items = handleB2PWebResponse(
      await this.client.get<B2PWebCollection<B2PWebVehicleTemplate>, B2PWebError>('/templateVehicles', {
        params: {
          page: options?.page,
          perPage: options?.perPage,
        },
        signal: options?.signal,
      })
    );
    return mapToCollection(items, mapToVehicleTemplate);
  }

  async removeTemplates(ids: string[], options?: AbortOptions): Promise<void> {
    await Promise.all(ids.map((id) => this.removeTemplate(id, options)));
  }

  async removeTemplate(id: string, options?: AbortOptions): Promise<void> {
    return handleB2PWebResponse(
      await this.client.delete<void, B2PWebError>(`/templateVehicles/${id}`, {
        signal: options?.signal,
      })
    );
  }
}
