import EventHandler from '@/shared/components/state-selector/event/handlers/EventHandler';

export default class CountryClickHandler extends EventHandler {
  constructor() {
    super();

    this.onClick = this.onClick.bind(this);
  }

  registerHook(): void {
    this.manager.root.addEventListener('click', this.onClick);
  }

  unregisterHook(): void {
    this.manager.root.removeEventListener('click', this.onClick);
  }

  onClick(event: MouseEvent): void {
    const country = (event.target as SVGElement)?.closest('.country-path');

    if (!country) {
      return;
    }

    if (!this.manager.isAllowed(event)) {
      return;
    }

    country.dispatchEvent(new CustomEvent('country-click', { bubbles: true }));

    this.manager.record(event);
  }
}
