import { AxiosInstance, AxiosRequestConfig } from 'axios';

import { useAppStore } from '@/store/modules/app';

export function setupUserLanguage(instance: AxiosInstance) {
  instance.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
    const locale = useAppStore().locale;

    if (locale) {
      config.headers = {
        ...config.headers,
        'Accept-Language': locale,
      };
    }

    return config;
  });
}
