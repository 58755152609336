import { App, Directive } from 'vue';

export const RootChild: Directive = {
  mounted: (el: HTMLElement) => {
    el.style.zIndex = '20000';
    document.body.appendChild(el);
  },
};

export const RootChildPlugin = {
  install(app: App): void {
    app.directive('root-child', RootChild);
  },
};
