import { SearchOptions } from '@/shared/api/directory/types';

export function composeSearchRequestParams(options: SearchOptions): object {
  return {
    ids: options.ids,
    nameStartWith: options.name,
    siretStartWith: options.siretNumber,
    tva: options.vatNumber,
    country: options.country,
    'vehicle[certifications]': options.vehicleCertifications,
    'vehicle[characteristics]': options.vehicleCharacteristics,
    'vehicle[typeIds]': options.vehicleTypes,
    zones: options.zone ? [options.zone] : undefined,
    subcontracting: options.allowSubcontracting,
    loading: options.allowLoading,
    page: options.page,
    per_page: options.perPage,
    ...(options.sort ? composeSearchRequestSortParam(options.sort) : undefined),
  };
}

function composeSearchRequestSortParam(sorting: string): object {
  const [direction, field] = sorting.split(/\b/, 2);

  return {
    [`orders[${field}]`]: direction === '+' ? 'ASC' : 'DESC',
  };
}
