
import { defineComponent, onMounted, onUnmounted } from 'vue';

function makeLinkElement(href: string): HTMLLinkElement {
  const element = document.createElement('link');

  element.setAttribute('href', href);
  element.setAttribute('rel', 'stylesheet');
  element.setAttribute('type', 'text/css');

  return element;
}

export default defineComponent({
  props: {
    href: { type: String, required: true },
  },
  setup(props, { slots }) {
    onMounted(() => {
      const head = document.querySelector('head');

      if (!head) {
        return;
      }

      const element = makeLinkElement(props.href);

      head.appendChild(element);

      onUnmounted(() => {
        element.parentNode?.removeChild(element);
      });
    });

    return () => slots.default?.()[0];
  },
});
