import { defineStore } from 'pinia';
import { computed, ComputedRef, Ref, ref, watch } from 'vue';

import { acl, getPermissions } from '@/services/acl';
import keycloak from '@/services/keycloak';
import config from '@/shared/config';
import Timer from '@/shared/timer';
import { get as localStorageGet, keys, set as localStorageSet } from '@/store/localStorage';

export interface Auth {
  accessToken: string;
  id: string;
  email: string;
  name: string;
  roles: string[];
}

function composeAuth(): Auth {
  return {
    accessToken: keycloak.token ?? '',
    id: keycloak.idTokenParsed?.user_id ?? '',
    email: keycloak.idTokenParsed?.email ?? '',
    name: keycloak.idTokenParsed?.name ?? '',
    roles: keycloak.idTokenParsed?.roles ?? [],
  };
}

export class ServiceUnavailableError extends Error {}

const minTokenValidity: number = config.auth.tokenValidityDuration;
// Timer used to refresh the token
const updateTokenTimer = new Timer();

export interface UseAppStoreReturn {
  auth: Ref<Auth | null>;
  isLoggedIn: ComputedRef<boolean>;
  locale: Ref<string>;

  login(): Promise<void>;
  logout(): Promise<void>;
  loadUserPermissions(): Promise<void>;
  setLocale(newLocale: string): void;
}

export const useAppStore = defineStore('app', (): UseAppStoreReturn => {
  const auth = ref<Auth | null>(null);
  const locale = ref(localStorageGet<string>(keys.locale, 'fr'));

  const isLoggedIn = computed(() => auth.value !== null);

  async function login(): Promise<void> {
    if (!keycloak.authenticated) {
      const isAuthenticated = await new Promise((resolve, reject) => {
        const timeoutID = setTimeout(() => reject(new ServiceUnavailableError()), config.auth.authServerTimeout * 1000);

        keycloak
          .init({
            onLoad: 'check-sso',
            pkceMethod: 'S256',
            silentCheckSsoRedirectUri: config.auth.silentCheckRedirectUri,
          })
          .then(resolve)
          .catch(reject)
          .finally(() => clearTimeout(timeoutID));
      });

      if (!isAuthenticated) {
        await keycloak.login({ locale: locale.value });
      }
    }

    auth.value = composeAuth();

    await loadUserPermissions();

    // We try to refresh the token if its minimum validity period is not respected
    await updateToken();
  }

  async function updateToken(force = false): Promise<void> {
    try {
      updateTokenTimer.clear();

      const isRefreshed = await keycloak.updateToken(force ? -1 : minTokenValidity);

      if (isRefreshed) {
        auth.value = composeAuth();

        await loadUserPermissions();
      }

      if (keycloak.tokenParsed?.exp) {
        updateTokenTimer.start(
          () => updateToken(true),
          (keycloak.tokenParsed.exp - new Date().getTime() / 1000 + (keycloak.timeSkew ?? 0) - minTokenValidity) * 1000
        );
      }
    } catch (e) {
      await logout();
    }
  }

  async function logout(): Promise<void> {
    updateTokenTimer.clear();

    auth.value = null;
    acl.setPermissions([]);

    await keycloak.logout();
  }

  async function loadUserPermissions(): Promise<void> {
    if (auth.value) {
      acl.setPermissions(await getPermissions(auth.value));
    }
  }

  function setLocale(newLocale: string): void {
    locale.value = newLocale;
  }

  keycloak.onAuthLogout = () => logout();

  watch(locale, () => {
    localStorageSet(keys.locale, locale.value);
  });

  return {
    auth,
    locale,
    isLoggedIn,

    login,
    logout,
    loadUserPermissions,
    setLocale,
  };
});
