import { onMounted, onUnmounted } from 'vue';

import EventStream from '@/shared/event-stream';

type EventStreamListeners<EventTypeMap> = {
  [Type in keyof EventTypeMap]: (payload: EventTypeMap[Type]) => void;
};

export function useEventStream<EventTypeMap>(
  stream: EventStream<EventTypeMap>,
  listeners: EventStreamListeners<EventTypeMap>
) {
  onMounted(() => {
    for (const key of Object.keys(listeners) as (keyof EventTypeMap)[]) {
      stream.addEventListener(key, listeners[key]);
    }
  });

  onUnmounted(() => {
    for (const key of Object.keys(listeners) as (keyof EventTypeMap)[]) {
      stream.removeEventListener(key, listeners[key]);
    }
  });
}
