import axios, { isAxiosError } from './axios';
import { AccessDeniedError } from './utils';

export async function getToken(signal?: AbortSignal): Promise<string> {
  try {
    const { data } = await axios.get<string>('b2p-optim/token', { signal });

    return data;
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 403) {
      throw new AccessDeniedError(e.message);
    }

    throw e;
  }
}
