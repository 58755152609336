import { GoodType, isGoodType } from '@/shared/enums/goodType';
import translator, { TranslatedMessage } from '@/shared/locale';

export enum TruckType {
  GENERAL_MERCHANDISE_TAUTLINER = '1',
  GENERAL_MERCHANDISE_VAN = '2',
  GENERAL_MERCHANDISE_TRAY = '3',
  GENERAL_MERCHANDISE_OTHER = '4',
  ALL = '5',
  SKIP_CEREALS = '6',
  SKIP_REINFORCED = '7',
  SKIP_MOVING_FLOOR = '8',
  SKIP_OTHER = '9',
  TANKER_PULVERIZED = '10',
  TANKER_LIQUID = '11',
  TANKER_OTHER = '12',
  GOVERNED_TEMPERATURE_COLD = '13',
  GOVERNED_TEMPERATURE_FROZEN = '14',
  GOVERNED_TEMPERATURE_NON_REFREGIRATED = '15',
  GOVERNED_TEMPERATURE_MULTI_TEMPERATURE = '16',
  GOVERNED_TEMPERATURE_OTHER = '17',
  CONTAINER_20_FEET = '18',
  CONTAINER_40_FEET = '19',
  MOVING_CATEGORY_1 = '20',
  MOVING_CATEGORY_2 = '21',
  MOVING_CATEGORY_3 = '22',
}

export function isTruckType(code: unknown): code is TruckType {
  return Object.values(TruckType).includes(code as TruckType);
}

const goodTypeMapping: Record<GoodType, TruckType[]> = {
  [GoodType.TANKER]: [TruckType.ALL, TruckType.TANKER_PULVERIZED, TruckType.TANKER_LIQUID, TruckType.TANKER_OTHER],
  [GoodType.MOVING]: [
    TruckType.ALL,
    TruckType.MOVING_CATEGORY_1,
    TruckType.MOVING_CATEGORY_2,
    TruckType.MOVING_CATEGORY_3,
  ],
  [GoodType.GENERAL_MERCHANDISE]: [
    TruckType.ALL,
    TruckType.GENERAL_MERCHANDISE_TAUTLINER,
    TruckType.GENERAL_MERCHANDISE_VAN,
    TruckType.GENERAL_MERCHANDISE_TRAY,
    TruckType.GENERAL_MERCHANDISE_OTHER,
  ],
  [GoodType.MESSAGING_AND_COURIER]: [TruckType.ALL],
  [GoodType.GOVERNED_TEMPERATURE]: [
    TruckType.ALL,
    TruckType.GOVERNED_TEMPERATURE_COLD,
    TruckType.GOVERNED_TEMPERATURE_FROZEN,
    TruckType.GOVERNED_TEMPERATURE_NON_REFREGIRATED,
    TruckType.GOVERNED_TEMPERATURE_MULTI_TEMPERATURE,
    TruckType.GOVERNED_TEMPERATURE_OTHER,
  ],
  [GoodType.BULK]: [TruckType.ALL],
  [GoodType.ALL]: [TruckType.ALL],
  [GoodType.EXCEPTIONAL]: [TruckType.ALL],
  [GoodType.SKIP]: [
    TruckType.ALL,
    TruckType.SKIP_CEREALS,
    TruckType.SKIP_REINFORCED,
    TruckType.SKIP_MOVING_FLOOR,
    TruckType.SKIP_OTHER,
  ],
  [GoodType.CONTAINER]: [TruckType.ALL, TruckType.CONTAINER_20_FEET, TruckType.CONTAINER_40_FEET],
};

let abbreviations: Record<TruckType, TranslatedMessage>;

export function abbreviation(code: string): string | undefined {
  return isTruckType(code) ? loadAbbreviations()[code] : undefined;
}

export function abbreviationList(goodType?: GoodType | string): Partial<Record<TruckType, TranslatedMessage>> {
  const abbreviations = loadAbbreviations();

  if (isGoodType(goodType)) {
    const truckTypes: Partial<Record<TruckType, TranslatedMessage>> = {};

    for (const truckType of goodTypeMapping[goodType]) {
      truckTypes[truckType] = abbreviations[truckType];
    }

    return truckTypes;
  }

  return { [TruckType.ALL]: abbreviations[TruckType.ALL] };
}

function loadAbbreviations(): Record<TruckType, TranslatedMessage> {
  if (!abbreviations) {
    abbreviations = {
      [TruckType.ALL]: translator().t('Tous'),

      [TruckType.CONTAINER_20_FEET]: translator().t('20p'),
      [TruckType.CONTAINER_40_FEET]: translator().t('40p'),

      [TruckType.GENERAL_MERCHANDISE_TAUTLINER]: translator().t('Taut'),
      [TruckType.GENERAL_MERCHANDISE_VAN]: translator().t('Fourg'),
      [TruckType.GENERAL_MERCHANDISE_TRAY]: translator().t('Plat'),
      [TruckType.GENERAL_MERCHANDISE_OTHER]: translator().t('Autr'),

      [TruckType.GOVERNED_TEMPERATURE_COLD]: translator().t('Frais'),
      [TruckType.GOVERNED_TEMPERATURE_FROZEN]: translator().t('Surg'),
      [TruckType.GOVERNED_TEMPERATURE_NON_REFREGIRATED]: translator().t('NRef'),
      [TruckType.GOVERNED_TEMPERATURE_MULTI_TEMPERATURE]: translator().t('MulT'),
      [TruckType.GOVERNED_TEMPERATURE_OTHER]: translator().t('Autr'),

      [TruckType.MOVING_CATEGORY_1]: translator().t('Cat1'),
      [TruckType.MOVING_CATEGORY_2]: translator().t('Cat2'),
      [TruckType.MOVING_CATEGORY_3]: translator().t('Cat3'),

      [TruckType.SKIP_CEREALS]: translator().t('Cerea'),
      [TruckType.SKIP_REINFORCED]: translator().t('Renf'),
      [TruckType.SKIP_MOVING_FLOOR]: translator().t('Fond'),
      [TruckType.SKIP_OTHER]: translator().t('Autr'),

      [TruckType.TANKER_PULVERIZED]: translator().t('Pulv'),
      [TruckType.TANKER_LIQUID]: translator().t('Liqu'),
      [TruckType.TANKER_OTHER]: translator().t('Autr'),
    };
  }

  return abbreviations;
}

let labels: Record<TruckType, TranslatedMessage>;

export function label(code: string): string | undefined {
  if (code === TruckType.ALL) {
    return translator().t('Tous véhicules');
  }

  if (isTruckType(code)) {
    return loadLabels()[code];
  }

  return undefined;
}

export function list(goodType?: GoodType | string, excludeAll = false): Partial<Record<TruckType, TranslatedMessage>> {
  const labels = loadLabels();

  const truckTypes: Partial<Record<TruckType, TranslatedMessage>> = {};

  for (const truckType of goodTypeMapping[isGoodType(goodType) ? goodType : GoodType.ALL]) {
    truckTypes[truckType] = labels[truckType];
  }

  if (excludeAll) {
    delete truckTypes[TruckType.ALL];
  }

  return truckTypes;
}

function loadLabels(): Record<TruckType, TranslatedMessage> {
  if (!labels) {
    labels = {
      [TruckType.ALL]: translator().t('Tous'),

      [TruckType.CONTAINER_20_FEET]: translator().t('20 pieds'),
      [TruckType.CONTAINER_40_FEET]: translator().t('40 pieds'),

      [TruckType.GENERAL_MERCHANDISE_TAUTLINER]: translator().t('Taut'),
      [TruckType.GENERAL_MERCHANDISE_VAN]: translator().t('Fourgon'),
      [TruckType.GENERAL_MERCHANDISE_TRAY]: translator().t('Plateau'),
      [TruckType.GENERAL_MERCHANDISE_OTHER]: translator().t('Autre'),

      [TruckType.GOVERNED_TEMPERATURE_COLD]: translator().t('Frais'),
      [TruckType.GOVERNED_TEMPERATURE_FROZEN]: translator().t('Surgelé'),
      [TruckType.GOVERNED_TEMPERATURE_NON_REFREGIRATED]: translator().t('Non réfrigéré'),
      [TruckType.GOVERNED_TEMPERATURE_MULTI_TEMPERATURE]: translator().t('Multi-température'),
      [TruckType.GOVERNED_TEMPERATURE_OTHER]: translator().t('Autre'),

      [TruckType.MOVING_CATEGORY_1]: translator().t('Catégorie 1'),
      [TruckType.MOVING_CATEGORY_2]: translator().t('Catégorie 2'),
      [TruckType.MOVING_CATEGORY_3]: translator().t('Catégorie 3'),

      [TruckType.SKIP_CEREALS]: translator().t('Céréalière'),
      [TruckType.SKIP_REINFORCED]: translator().t('Renforcée'),
      [TruckType.SKIP_MOVING_FLOOR]: translator().t('Fond mouvant'),
      [TruckType.SKIP_OTHER]: translator().t('Autre'),

      [TruckType.TANKER_PULVERIZED]: translator().t('Pulvée'),
      [TruckType.TANKER_LIQUID]: translator().t('Liquide'),
      [TruckType.TANKER_OTHER]: translator().t('Autre'),
    };
  }

  return labels;
}
