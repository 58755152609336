export interface ApiResponsePagination {
  limit: number;
  pageCount: number;
  currentPage: number;
  totalCount: number;
}

export interface ApiCollectionResponse<T> {
  items: T[];
  pagination: ApiResponsePagination;
}

export interface PaginationOptions {
  readonly page?: number;
  readonly perPage?: number;
}

export interface SortingOptions {
  readonly sort?: string;
}

export interface Collection<T> {
  items: T[];
  page: number;
  pageCount: number;
  limit: number;
  totalCount: number;
}

export interface ValidationError {
  code: string;
  field: string;
  message: string;
}

export class AccessDeniedError extends Error {}

export class FormValidationError extends Error {
  public errors: ValidationError[] = [];

  constructor(message: string, errors: ValidationError[]) {
    super(message);
    this.errors = errors;
  }
}
