import translator, { TranslatedMessage } from '@/shared/locale';

export enum GoodType {
  TANKER = '7',
  MOVING = '14',
  GENERAL_MERCHANDISE = '19',
  MESSAGING_AND_COURIER = '21',
  GOVERNED_TEMPERATURE = '26',
  BULK = '35',
  ALL = '37',
  EXCEPTIONAL = '38',
  SKIP = '39',
  CONTAINER = '40',
}

export function isGoodType(code: unknown): code is GoodType {
  return Object.values(GoodType).includes(code as GoodType);
}

let abbreviations: Record<GoodType, TranslatedMessage>;

export function abbreviation(code: string): string | undefined {
  if (isGoodType(code)) {
    return loadAbbreviations()[code];
  }

  return undefined;
}

export function abbreviationList(excludeAll = false): Partial<Record<GoodType, TranslatedMessage>> {
  const abbreviations: Partial<Record<GoodType, TranslatedMessage>> = { ...loadAbbreviations() };

  if (excludeAll) {
    delete abbreviations[GoodType.ALL];
  }

  return abbreviations;
}

function loadAbbreviations(): Record<GoodType, TranslatedMessage> {
  if (!abbreviations) {
    abbreviations = {
      [GoodType.ALL]: translator().t('Tt'),
      [GoodType.BULK]: translator().t('TV'),
      [GoodType.CONTAINER]: translator().t('C'),
      [GoodType.EXCEPTIONAL]: translator().t('TE'),
      [GoodType.GENERAL_MERCHANDISE]: translator().t('MG'),
      [GoodType.GOVERNED_TEMPERATURE]: translator().t('TD'),
      [GoodType.MESSAGING_AND_COURIER]: translator().t('MC'),
      [GoodType.MOVING]: translator().t('Dem'),
      [GoodType.SKIP]: translator().t('B'),
      [GoodType.TANKER]: translator().t('Cit'),
    };
  }

  return abbreviations;
}

let labels: Record<GoodType, TranslatedMessage>;

export function label(code: string): string | undefined {
  if (code === GoodType.ALL) {
    return translator().t('Toutes marchandises');
  }

  if (isGoodType(code)) {
    return loadLabels()[code];
  }

  return undefined;
}

export function list(excludeAll = false): Partial<Record<GoodType, TranslatedMessage>> {
  const labels: Partial<Record<GoodType, TranslatedMessage>> = { ...loadLabels() };

  if (excludeAll) {
    delete labels[GoodType.ALL];
  }

  return labels;
}

function loadLabels(): Record<GoodType, TranslatedMessage> {
  if (!labels) {
    labels = {
      [GoodType.ALL]: translator().t('Tout'),
      [GoodType.BULK]: translator().t('Transports volumineux'),
      [GoodType.CONTAINER]: translator().t('Containers'),
      [GoodType.EXCEPTIONAL]: translator().t('Transport exceptionnel'),
      [GoodType.GENERAL_MERCHANDISE]: translator().t('Marchandises Générales'),
      [GoodType.GOVERNED_TEMPERATURE]: translator().t('Température dirigée'),
      [GoodType.MESSAGING_AND_COURIER]: translator().t('Messageries et course'),
      [GoodType.MOVING]: translator().t('Déménagement'),
      [GoodType.SKIP]: translator().t('Bennes'),
      [GoodType.TANKER]: translator().t('Citernes'),
    };
  }

  return labels;
}
