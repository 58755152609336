import { onUnmounted, watch } from 'vue';

import { RefElement, unrefElement } from '@/shared/composables/utils/unrefElement';

export function useIntersectionObserver(
  target: RefElement,
  callback: IntersectionObserverCallback,
  options?: IntersectionObserverInit
) {
  const observer = new IntersectionObserver(callback, options);

  watch(
    () => unrefElement(target),
    (element) => {
      observer.disconnect();

      if (element) {
        observer.observe(element);
      }
    },
    { immediate: true, flush: 'post' }
  );

  onUnmounted(() => {
    observer.disconnect();
  });
}
