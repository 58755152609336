import EventManager from '@/shared/components/state-selector/event/EventManager';

export default abstract class EventHandler {
  protected manager!: EventManager;

  setEventManager(manager: EventManager): void {
    this.manager = manager;
  }

  abstract registerHook(): void;
  abstract unregisterHook(): void;
}
