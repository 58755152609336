import * as api from '@/shared/api/directory';
import { AbortOptions, Collection } from '@/shared/dataProviders/common';
import {
  AxisInput,
  Comment,
  CustomerFile,
  Document,
  EnumItem,
  LoadingRestrictionType,
  MyAxis,
  MyCustomerFile,
  MyCustomerFileInput,
  MyDocument,
  SearchOptions,
  SearchResult,
  SubcontractingRestrictionType,
  VehicleType,
  VehicleTypeInput,
} from '@/shared/dataProviders/directory';
import {
  composeArray,
  composeCollection,
  composeComment,
  composeComments,
  composeCustomerFile,
  composeDocuments,
  composeMyCustomerFile,
  composeMyDocuments,
  composeScores,
  composeSearchResults,
} from '@/shared/dataProviders/directory/mappers';

export async function search(options?: SearchOptions): Promise<Collection<SearchResult>> {
  return composeCollection(await api.search(options), composeSearchResults);
}

export async function fetchScores(ids: string[], options?: AbortOptions): Promise<Record<string, string | null>> {
  if (!ids.length) {
    return {};
  }

  try {
    const results = await api.search({
      ids,
      perPage: ids.length,
      signal: options?.signal,
    });

    return composeScores(results.items);
  } catch (e) {
    if (e instanceof api.ForbiddenError) {
      return {};
    }
    throw e;
  }
}

export async function getCustomerFile(id: string, options?: AbortOptions): Promise<CustomerFile | undefined> {
  try {
    return composeCustomerFile(await api.getCustomerFile(id, options));
  } catch (error) {
    if (error instanceof api.NotFoundError) {
      return;
    }
  }
}

export async function getMyCustomerFile(options?: AbortOptions): Promise<MyCustomerFile | undefined> {
  return composeMyCustomerFile(await api.getMyCustomerFile(options));
}

export async function createOrUpdateMyCustomerFile(input: MyCustomerFileInput): Promise<MyCustomerFile> {
  return composeMyCustomerFile(await api.createOrUpdateMyCustomerFile(input));
}

export async function removeMyVehicleType(id: string): Promise<void> {
  await api.removeMyVehicleType(id);
}

export async function updateMyVehicleType(
  input: VehicleTypeInput & { id: VehicleType['id'] }
): Promise<VehicleType | undefined> {
  return await api.updateMyVehicleType(input);
}

export async function createMyVehicleType(input: VehicleTypeInput): Promise<VehicleType | undefined> {
  return await api.createMyVehicleType(input);
}

export async function removeMyAxis(id: string): Promise<void> {
  await api.removeMyAxis(id);
}

export async function createMyAxis(input: AxisInput): Promise<MyAxis | undefined> {
  return await api.createMyAxis(input);
}

export async function getCustomerFileComments(customerId: string, options?: AbortOptions): Promise<Comment[]> {
  return composeArray(await api.getCustomerFileComments(customerId, options), composeComments);
}

export async function createCustomerFileComment(
  customerId: string,
  input: api.CommentInput
): Promise<Comment | undefined> {
  return composeComment(await api.createComment(customerId, input));
}

export async function deleteCustomerFileComment(customerId: string, comment: Comment): Promise<void> {
  await api.deleteComment(customerId, comment.id);
}

export async function getCustomerFileDocuments(id: string, options?: AbortOptions): Promise<Document[] | undefined> {
  try {
    return composeArray(await api.getCustomerFileDocuments(id, options), composeDocuments);
  } catch (error) {
    if (error instanceof api.NotFoundError) {
      return;
    }
  }
}

export async function getVehicleTypes(options?: AbortOptions): Promise<EnumItem<number>[]> {
  return await api.getVehicleTypes(options);
}

export async function getMyDocuments(options?: AbortOptions): Promise<MyDocument[]> {
  return composeArray(await api.getMyDocuments(options), composeMyDocuments);
}

export async function sendMyDocuments(
  recipientEmail: string,
  documentTypeIds: Document['type']['id'][],
  language: string
): Promise<void> {
  await api.sendMyDocuments(recipientEmail, documentTypeIds, language);
}

export async function setLoadingRestriction(
  id: string,
  state: LoadingRestrictionType.Allowed | LoadingRestrictionType.Warned | LoadingRestrictionType.Forbidden,
  reason?: string
): Promise<void> {
  if (state === LoadingRestrictionType.Allowed) {
    await api.allowLoading(id, reason);
  } else if (state === LoadingRestrictionType.Warned) {
    await api.warnLoading(id, reason);
  } else {
    await api.forbidLoading(id, reason);
  }
}
export async function setSubcontractingRestriction(
  id: string,
  state: SubcontractingRestrictionType.Allowed | SubcontractingRestrictionType.Forbidden,
  reason?: string
): Promise<void> {
  if (state === SubcontractingRestrictionType.Allowed) {
    await api.allowSubcontracting(id, reason);
  } else {
    await api.forbidSubcontracting(id, reason);
  }
}
