import { onUnmounted, watch } from 'vue';

import { RefElement, unrefElement } from '@/shared/composables/utils/unrefElement';

export function useResizeObserver(
  target: RefElement,
  callback: ResizeObserverCallback,
  options?: ResizeObserverOptions
) {
  const observer = new ResizeObserver(callback);

  watch(
    () => unrefElement(target),
    (element) => {
      observer.disconnect();

      if (element) {
        observer.observe(element, options);
      }
    },
    { immediate: true, flush: 'post' }
  );

  onUnmounted(() => {
    observer.disconnect();
  });
}
