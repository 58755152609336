import { App } from 'vue';

import setupAxios from '@/setup/axios';
import setupComponents from '@/setup/components';
import setupCookies from '@/setup/cookies';
import setupDirectives from '@/setup/directives';
import setupEventStream from '@/setup/event-stream';
import setupForms from '@/setup/forms';
import setupI18n from '@/setup/i18n';
import setupI18nIsoCountries from '@/setup/i18n-iso-countries';
import setupMatomo from '@/setup/matomo';
import setupRouter from '@/setup/router';
import setupStore from '@/setup/store';

export default function (app: App) {
  setupStore(app);
  setupI18n(app);
  setupRouter(app);
  setupComponents();
  setupCookies();
  setupDirectives(app);

  setupAxios();
  setupEventStream();
  setupForms();
  setupI18nIsoCountries();
  setupMatomo(app);
}
