import { onMounted, onUnmounted } from 'vue';

import eventBus from '@/event-bus';

export type EventBusListener = (...args: never[]) => void;

export function useEventBus(map?: Record<string, EventBusListener>) {
  const registered = new Map<string, Set<EventBusListener>>();

  if (map) {
    for (const key of Object.keys(map)) {
      let listeners = registered.get(key);

      if (!listeners) {
        registered.set(key, (listeners = new Set()));
      }

      listeners.add(map[key]);
    }
  }

  onMounted(() => {
    for (const [key, listeners] of registered.entries()) {
      for (const listener of listeners) {
        eventBus.$on(key, listener);
      }
    }
  });

  onUnmounted(() => {
    for (const [key, listeners] of registered.entries()) {
      for (const listener of listeners) {
        eventBus.$off(key, listener);
      }
    }
    registered.clear();
  });

  // TODO Return an object containing methods : on, once, off, etc.
}
