import { DefaultDateTimeFormatSchema } from 'vue-i18n';

const datetimeFormats: DefaultDateTimeFormatSchema = {
  'dd/MM/yyyy HH:mm': { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' },
  'dd/MM/yyyy': { year: 'numeric', month: '2-digit', day: '2-digit' },
  'dd/MM': { month: '2-digit', day: '2-digit' },
  'dd/MM HH:mm': { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' },
  'HH:mm': { hour: '2-digit', minute: '2-digit' },
};

export default datetimeFormats;
