import { computed, Ref, unref } from 'vue';

import { Scale } from '@/shared/types';

export function useScaleMatchers(scale: Scale | Ref<Scale>) {
  const isSmallScale = computed(() => unref(scale) === 'sm');
  const isMediumScale = computed(() => unref(scale) === 'md');
  const isLargeScale = computed(() => unref(scale) === 'lg');
  const isExtraLargeScale = computed(() => unref(scale) === 'xl');
  const isSmallScaleOrSmaller = computed(() => isSmallScale.value);
  const isMediumScaleOrSmaller = computed(() => isSmallScale.value || isMediumScale.value);
  const isLargeScaleOrSmaller = computed(() => isMediumScaleOrSmaller.value || isLargeScale.value);
  const isExtraLargeScaleOrSmaller = computed(() => isLargeScaleOrSmaller.value || isExtraLargeScale.value);
  const isSmallScaleOrBigger = computed(() => isSmallScale.value || isMediumScaleOrBigger.value);
  const isMediumScaleOrBigger = computed(() => isMediumScale.value || isLargeScaleOrBigger.value);
  const isLargeScaleOrBigger = computed(() => isLargeScale.value || isExtraLargeScaleOrBigger.value);
  const isExtraLargeScaleOrBigger = computed(() => isExtraLargeScale.value);

  return {
    isSmallScale,
    isMediumScale,
    isLargeScale,
    isExtraLargeScale,
    isSmallScaleOrSmaller,
    isMediumScaleOrSmaller,
    isLargeScaleOrSmaller,
    isExtraLargeScaleOrSmaller,
    isSmallScaleOrBigger,
    isMediumScaleOrBigger,
    isLargeScaleOrBigger,
    isExtraLargeScaleOrBigger,
  };
}
