import translator, { TranslatedMessage } from '@/shared/locale';

export enum ReasonType {
  CANCELED = 'canceled',
  SOLD_TO_GROUP = 'sold_to_group',
  SOLD_TO_COMPANY = 'sold_to_company',
}

export function list(): Record<ReasonType, TranslatedMessage> {
  return {
    [ReasonType.CANCELED]: translator().t('Offre annulée'),
    [ReasonType.SOLD_TO_GROUP]: translator().t('Offre vendue à un membre de mon groupe/groupement'),
    [ReasonType.SOLD_TO_COMPANY]: translator().t('Offre vendue à une autre société'),
  };
}
