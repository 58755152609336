import { onUnmounted } from 'vue';

import { AbortManager } from '@/shared/abort-manager';

export function useCancellable() {
  const abortManager = new AbortManager();

  onUnmounted(() => {
    abortManager.clear();
  });

  return {
    abort(key: string) {
      abortManager.abort(key);
    },

    mostRecent(key: string, callable: (signal: AbortSignal) => Promise<void>): Promise<void> {
      return abortManager.mostRecent(key, callable);
    },
  };
}
