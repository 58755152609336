import axios, { AxiosResponse } from 'axios';

import { CancelledRequestError } from '@/shared/abort-manager';

export async function cancellable<ResultType>(
  response: Promise<AxiosResponse<ResultType>>
): Promise<AxiosResponse<ResultType>> {
  try {
    return await response;
  } catch (e) {
    if (axios.isCancel(e)) {
      throw new CancelledRequestError();
    }
    throw e;
  }
}
