import {
  bookmarkConsultedOffer as bookmarkApiConsultedSearchOffer,
  Collection,
  ConsultedSearchOffer as ApiConsulteSearchOfferOffer,
  getConsultedSearchOffer as getApiSearchConsultedOffer,
  getConsultedSearchOffers as getApiSearchConsultedOffers,
  getSearchOffer as getApiSearchOffer,
  getSearchOffers as getApiSearchOffers,
  GetSearchOffersOptions,
  PaginationOptions,
  SearchOffer as ApiSearchOffer,
  SearchOfferDetail as ApiSearchOfferDetail,
  unbookmarkConsultedOffer as unbookmarkApiConsultedOffer,
  updateConsultedOfferBookmark as updateBookmarkApiConsultedSearchOffer,
} from '@/shared/api/search';
import { GoodType } from '@/shared/enums/goodType';
import { TruckType } from '@/shared/enums/truckType';
import { ConsultedSearchOffer, ConsultedSearchOfferListFilters, SearchOffer, SearchOfferDetail } from '@/shared/types';

function composeSearchOffer(apiSearchOffer: ApiSearchOffer): SearchOffer {
  return {
    id: apiSearchOffer.id,
    customer: apiSearchOffer.customer,
    forbiddingLevel: apiSearchOffer.forbiddingLevel,
    goodType: apiSearchOffer.goodType as GoodType,
    truckTypes: apiSearchOffer.truckTypes as TruckType[],
    lading: apiSearchOffer.lading,
    delivery: apiSearchOffer.delivery,
    hazardousMaterials: apiSearchOffer.hazardousMaterials,
    weight: apiSearchOffer.weight,
    length: apiSearchOffer.length,
    volume: apiSearchOffer.volume,
    distance: apiSearchOffer.distance,
    exchangeType: apiSearchOffer.exchangeType,
    isConsulted: apiSearchOffer.isConsulted,
    updatedAt: apiSearchOffer.updatedAt,
  };
}

function composeSearchOfferDetail(apiSearchOfferDetail: ApiSearchOfferDetail): SearchOfferDetail {
  return {
    ...composeSearchOffer(apiSearchOfferDetail),

    contact: apiSearchOfferDetail.contact,
    notes: apiSearchOfferDetail.notes,
    price: apiSearchOfferDetail.price,
    orderRef: apiSearchOfferDetail.orderRef,
  };
}

function composeConsultedSearchOffer(apiSearchOffer: ApiConsulteSearchOfferOffer): ConsultedSearchOffer {
  return {
    id: apiSearchOffer.id,
    customer: apiSearchOffer.customer,
    goodType: apiSearchOffer.goodType as GoodType,
    truckTypes: apiSearchOffer.truckTypes as TruckType[],
    lading: apiSearchOffer.lading,
    delivery: apiSearchOffer.delivery,
    hazardousMaterials: apiSearchOffer.hazardousMaterials,
    weight: apiSearchOffer.weight,
    length: apiSearchOffer.length,
    volume: apiSearchOffer.volume,
    distance: apiSearchOffer.distance,
    consultedAt: apiSearchOffer.consultedAt,
    contact: apiSearchOffer.contact,
    notes: apiSearchOffer.notes,
    price: apiSearchOffer.price,
    orderRef: apiSearchOffer.orderRef,
    isBookmarked: apiSearchOffer.isBookmarked,
    userNotes: apiSearchOffer.userNotes,
    userPrice: apiSearchOffer.userPrice,
  };
}

export async function getSearchOffer(id: string, searchId?: string): Promise<SearchOfferDetail | undefined> {
  const apiOffer = await getApiSearchOffer(searchId || '', id);

  if (!apiOffer) {
    return undefined;
  }

  return composeSearchOfferDetail(apiOffer);
}

export async function getSearchOffers({
  searchId,
  options,
}: {
  searchId: string;
  options?: GetSearchOffersOptions;
}): Promise<Collection<SearchOffer>> {
  const collection = await getApiSearchOffers(searchId, options);

  return {
    ...collection,

    items: collection.items.map((searchOffer) => composeSearchOffer(searchOffer)),
  };
}

export async function getSearchConsultedOffer(id: string): Promise<ConsultedSearchOffer | undefined> {
  const apiOffer = await getApiSearchConsultedOffer(id);

  if (!apiOffer) {
    return undefined;
  }

  return composeConsultedSearchOffer(apiOffer);
}

export async function getSearchConsultedOffers(
  options: ConsultedSearchOfferListFilters & PaginationOptions = {}
): Promise<Collection<ConsultedSearchOffer>> {
  const collection = await getApiSearchConsultedOffers({ ...options });

  return {
    ...collection,

    items: collection.items.map((consultedSearchOffer) => composeConsultedSearchOffer(consultedSearchOffer)),
  };
}

export async function bookmark(id: string, data: { price?: number; notes?: string }): Promise<void> {
  await bookmarkApiConsultedSearchOffer(id, data);
}

export async function unbookmark(ids: string[]): Promise<void> {
  await unbookmarkApiConsultedOffer(ids);
}

export async function updateBookmark(
  id: string,
  data: { price?: number; notes?: string }
): Promise<ConsultedSearchOffer> {
  return composeConsultedSearchOffer(await updateBookmarkApiConsultedSearchOffer(id, data));
}
