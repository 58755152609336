import { onBeforeUnmount, ref } from 'vue';

type Status = 'started' | 'stopped';

export function useInterval(job: () => Promise<void> | void, intervalInMs: number) {
  const id = ref<number>();
  const status = ref<Status>('stopped');

  const start = (options?: { immediate?: boolean }) => {
    if (status.value !== 'stopped') {
      return;
    }

    status.value = 'started';

    scheduleNext(options);
  };

  const stop = () => {
    if (status.value !== 'started') {
      return;
    }

    status.value = 'stopped';

    clearTimeout(id.value);
    id.value = undefined;
  };

  const scheduleNext = (options?: { immediate?: boolean }) => {
    if (status.value !== 'started') {
      return;
    }

    id.value = setTimeout(
      async () => {
        try {
          await job();
        } finally {
          scheduleNext();
        }
      },
      options?.immediate ? 0 : intervalInMs
    );
  };

  onBeforeUnmount(() => {
    stop();
  });

  return { start, stop };
}
