import { watch } from 'vue';

import stream from '@/services/event-stream';
import { useAppStore } from '@/store/modules/app';

export default function () {
  const appStore = useAppStore();

  watch(
    () => appStore.isLoggedIn,
    (isLoggedIn) => {
      isLoggedIn ? stream.open() : stream.close();
    },
    { immediate: true }
  );
}
