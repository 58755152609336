import hotkeys from 'hotkeys-js';
import { App, Directive } from 'vue';

interface HotkeysOptions {
  [shortcut: string]: () => void;
}

function bind(el: HTMLElement, options: HotkeysOptions) {
  for (const shortcut of Object.keys(options)) {
    hotkeys(shortcut, options[shortcut]);
  }
}

function unbind(el: HTMLElement, options: HotkeysOptions) {
  for (const shortcut of Object.keys(options)) {
    hotkeys.unbind(shortcut, options[shortcut]);
  }
}

export const Hotkeys: Directive<HTMLElement, HotkeysOptions> = {
  beforeMount(el, binding) {
    bind(el, binding.value);
  },
  updated(el, binding) {
    if (binding.value === binding.oldValue) {
      return;
    }

    if (binding.oldValue) {
      unbind(el, binding.oldValue);
    }

    bind(el, binding.value);
  },
  unmounted(el, binding) {
    unbind(el, binding.value);
  },
};

export const HotkeysPlugin = {
  install(app: App): void {
    app.directive('hotkeys', Hotkeys);
  },
};
