import { DateTime } from 'luxon';
import qs from 'qs';

import axios from './axios';

interface ApiAlert {
  searchId: string;
  newOffersCount: number;
}

interface AlertRequest {
  searchId: string;
  since?: DateTime;
}

export interface Alert {
  searchId: string;
  newOffersCount: number;
}

export async function fetch(requests: AlertRequest[]): Promise<Alert[]> {
  const { data } = await axios.get<ApiAlert[]>(`searches/alerts`, {
    params: {
      items: requests.map((request) => ({
        search_id: request.searchId,
        since: request.since?.set({ millisecond: 0 }).toISO({ suppressMilliseconds: true }),
      })),
    },

    paramsSerializer(params) {
      return qs.stringify(params, { arrayFormat: 'indices' });
    },
  });

  return data;
}
