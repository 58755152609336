export interface ApiContact {
  name?: string;
  phone?: string;
  fax?: string;
  mobile?: string;
  email?: string;
  languages?: string[];
}

export interface Contact {
  name: string | null;
  phone: string | null;
  fax: string | null;
  mobile: string | null;
  email: string | null;
  languages: string[];
}

export function fromApi(data?: ApiContact): Contact {
  return {
    name: data?.name ? data.name : null,
    phone: data?.phone ? data.phone : null,
    fax: data?.fax ? data.fax : null,
    mobile: data?.mobile ? data.mobile : null,
    email: data?.email ? data.email : null,
    languages: data?.languages ? data.languages : [],
  };
}
