import { options } from 'floating-vue';

export default () => {
  options.themes['autocomplete'] = {
    $extend: 'dropdown',
    triggers: [],
    delay: {
      show: 0,
      hide: 0,
    },
    autoHide: false,
    placement: 'bottom-start',
    distance: 0,
  };
  options.themes['main-nav'] = {
    $extend: 'menu',
    delay: {
      show: 100,
      hide: 0,
    },
    placement: 'bottom-start',
    distance: 0,
  };
};
