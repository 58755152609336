export class CancelledRequestError extends Error {}

export class AbortManager {
  private controllers = new Map<string, AbortController>();

  abort(key: string): void {
    const controller = this.controllers.get(key);
    controller?.abort();
    this.controllers.delete(key);
  }

  clear(): void {
    this.controllers.forEach((controller: AbortController) => {
      controller.abort();
    });

    this.controllers.clear();
  }

  async mostRecent(key: string, callable: (signal: AbortSignal) => Promise<void>): Promise<void> {
    this.abort(key);

    const controller = new AbortController();

    this.controllers.set(key, controller);

    try {
      await callable(controller.signal);

      this.controllers.delete(key);
    } catch (e) {
      if (e instanceof CancelledRequestError) {
        return;
      }

      this.controllers.delete(key);

      throw e;
    }
  }
}
