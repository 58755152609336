import config from '@/shared/config';

export interface CustomerContact {
  id: string;
  country: string;
  name: string;
  logo: string | null;
  zone: string;
}

function composeLogoUrl(path: string): string {
  if (!path) {
    return '';
  }

  const baseUrl = config.urls.customerLogo.endsWith('/')
    ? config.urls.customerLogo.slice(0, -1)
    : config.urls.customerLogo;

  path = path.startsWith('/') ? path.slice(1, path.length) : path;

  return `${baseUrl}/${path}`;
}

export function fromApi(customerContact: CustomerContact): CustomerContact {
  return {
    ...customerContact,

    logo: (customerContact.logo && composeLogoUrl(customerContact.logo)) || null,
  };
}
