import axios from 'axios';

import { setupAuthentication } from '@/shared/api/util/interceptors/setupAuthentication';
import { setupUserLanguage } from '@/shared/api/util/interceptors/setupUserLanguage';
import config from '@/shared/config';
import { AxiosRestClient } from '@/shared/dataProviders/common/AxiosRestClient';
import { RestClient } from '@/shared/dataProviders/common/RestClient';
import {
  B2PWebThirdPartyVehicleDataProvider,
  B2PWebVehicleDataProvider,
  B2PWebVehicleSearchDataProvider,
} from '@/shared/dataProviders/vehicle/b2pweb';

import { ThirdPartyVehicleDataProvider, VehicleDataProvider, VehicleSearchDataProvider } from '.';

export * from './ThirdPartyVehicleDataProvider';
export * from './types';
export * from './VehicleDataProvider';
export * from './VehicleSearchDataProvider';

let thirdPartyVehicleDataProvider: ThirdPartyVehicleDataProvider;
export function useThirdPartyVehicleDataProvider(): ThirdPartyVehicleDataProvider {
  if (!thirdPartyVehicleDataProvider) {
    thirdPartyVehicleDataProvider = new B2PWebThirdPartyVehicleDataProvider(useVehicleApiClient());
  }
  return thirdPartyVehicleDataProvider;
}

let vehicleDataProvider: VehicleDataProvider;
export function useVehicleDataProvider(): VehicleDataProvider {
  if (!vehicleDataProvider) {
    vehicleDataProvider = new B2PWebVehicleDataProvider(useVehicleApiClient());
  }
  return vehicleDataProvider;
}

let vehicleSearchDataProvider: VehicleSearchDataProvider;
export function useVehicleSearchDataProvider(): VehicleSearchDataProvider {
  if (!vehicleSearchDataProvider) {
    vehicleSearchDataProvider = new B2PWebVehicleSearchDataProvider(useVehicleApiClient());
  }
  return vehicleSearchDataProvider;
}

let client: RestClient;
function useVehicleApiClient(): RestClient {
  if (!client) {
    const axiosInstance = axios.create({
      baseURL: config.urls.vehicleApi,
    });

    setupAuthentication(axiosInstance);
    setupUserLanguage(axiosInstance);

    client = new AxiosRestClient(axiosInstance);
  }
  return client;
}
