import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

import { getAvailableLayouts, GridItem, GridLayout } from '@/shared/grid';
import { Search } from '@/shared/types';
import { get as localStorageGet, keys, set as localStorageSet } from '@/store/localStorage';

export const useSearchGridStore = defineStore('search-grid', () => {
  const activeSearchIds = ref<string[]>(localStorageGet<string[]>(keys.activeSearchIds, []));
  const preferredLayouts = ref<GridItem[][]>(
    localStorageGet<GridItem[][]>(keys.preferredLayouts, [
      getAvailableLayouts(0)[0].items,
      getAvailableLayouts(1)[0].items,
      getAvailableLayouts(2)[0].items,
      getAvailableLayouts(3)[0].items,
      getAvailableLayouts(4)[0].items,
      getAvailableLayouts(5)[0].items,
      getAvailableLayouts(6)[0].items,
    ])
  );

  function setPreferredLayout(layout: GridLayout) {
    preferredLayouts.value.splice(layout.items.length, 1, layout.items);
  }

  function onSearchUpdated(payload: { previousId: Search['id']; newId: Search['id'] }): void {
    if (activeSearchIds.value.length > 1) {
      replace({ id: payload.previousId, newId: payload.newId });
    } else {
      set([payload.newId]);
    }
  }

  function set(searchIds: string[]) {
    activeSearchIds.value = searchIds.slice(0, 6);
  }

  function canAdd() {
    return activeSearchIds.value.length < 6;
  }

  function add(id: string) {
    if (!canAdd()) {
      return;
    }

    activeSearchIds.value = [...activeSearchIds.value, id];
  }

  function replace({ id, newId }: { id: string; newId: string }) {
    activeSearchIds.value = activeSearchIds.value.map((searchId) => {
      if (searchId === id) {
        return newId;
      } else if (searchId === newId) {
        return id;
      }

      return searchId;
    });
  }

  function remove(id: string) {
    activeSearchIds.value = activeSearchIds.value.filter((searchId) => searchId !== id);
  }

  watch(
    preferredLayouts,
    () => {
      localStorageSet(keys.preferredLayouts, preferredLayouts.value);
    },
    { deep: true }
  );

  watch(activeSearchIds, () => {
    localStorageSet(keys.activeSearchIds, activeSearchIds.value);
  });

  return {
    preferredLayouts,
    setPreferredLayout,

    activeSearchIds,
    canAdd,
    add,
    remove,
    replace,
    set,

    onSearchUpdated,
  };
});
