import EventHandler from '@/shared/components/state-selector/event/handlers/EventHandler';

export default class EventManager {
  private readonly eventMapping: Record<string, string> = {
    mousedown: 'click',
    mouseup: 'click',
  };

  private lastEvents: Record<string, number> = {};

  constructor(public readonly root: SVGElement, private handlers: EventHandler[]) {
    this.handlers.forEach((handler) => {
      handler.setEventManager(this);
    });
  }

  registerHooks(): void {
    this.handlers.forEach((handler) => {
      handler.registerHook();
    });
  }

  unregisterHooks(): void {
    this.handlers.forEach((handler) => {
      handler.unregisterHook();
    });
  }

  isAllowed(event: Event): boolean {
    const type = this.mapEventType(event);

    if (type in this.lastEvents) {
      return event.timeStamp - this.lastEvents[type] > 0;
    }

    return true;
  }

  record(event: Event): void {
    this.lastEvents[this.mapEventType(event)] = event.timeStamp;
  }

  private mapEventType(event: Event): string {
    return this.eventMapping[event.type] ?? event.type;
  }
}
