export interface GridItem {
  w: number;
  h: number;
}

export interface GridLayout {
  id: string;
  items: GridItem[];
}

function createLayout(id: string, items: GridItem[] = []): GridLayout {
  return { id, items };
}

export const GridColSpanClassName: Record<number, string> = {
  1: 'col-span-1',
  2: 'col-span-2',
  3: 'col-span-3',
  4: 'col-span-4',
  5: 'col-span-5',
  6: 'col-span-6',
};
export const GridRowSpanClassName: Record<number, string> = { 1: 'row-span-1', 2: 'row-span-2' };

export function getAvailableLayouts(size: number): GridLayout[] {
  switch (size) {
    case 0:
      return [createLayout('empty')];

    case 1:
      return [createLayout('single', [{ w: 6, h: 2 }])];

    case 2:
      return [
        createLayout('2-cols', new Array(2).fill({ w: 3, h: 2 })),
        createLayout('2-rows', new Array(2).fill({ w: 6, h: 1 })),
      ];

    case 3:
      return [
        createLayout('3-cols', new Array(3).fill({ w: 2, h: 2 })),
        createLayout('1-col-on-left-side-and-2-rows', [{ w: 2, h: 2 }, ...new Array(2).fill({ w: 4, h: 1 })]),
        createLayout('2-rows-and-1-col-on-right-side', [
          { w: 4, h: 1 },
          { w: 2, h: 2 },
          { w: 4, h: 1 },
        ]),
        createLayout('1-row-at-top-and-2-cols-at-bottom', [{ w: 6, h: 1 }, ...new Array(2).fill({ w: 3, h: 1 })]),
        createLayout('2-cols-at-top-and-1-row-at-bottom', [...new Array(2).fill({ w: 3, h: 1 }), { w: 6, h: 1 }]),
      ];

    case 4:
      return [
        createLayout('4-equal-blocks', new Array(4).fill({ w: 3, h: 1 })),
        createLayout('4-blocks-composed-of-2-smaller-at-bottom-right', [
          { w: 2, h: 2 },
          { w: 4, h: 1 },
          ...new Array(2).fill({ w: 2, h: 1 }),
        ]),
        createLayout('4-blocks-composed-of-2-smaller-at-top-right', [
          { w: 2, h: 2 },
          ...new Array(2).fill({ w: 2, h: 1 }),
          { w: 4, h: 1 },
        ]),
        createLayout('4-blocks-composed-of-2-smaller-at-bottom-left', [
          { w: 4, h: 1 },
          { w: 2, h: 2 },
          ...new Array(2).fill({ w: 2, h: 1 }),
        ]),
        createLayout('4-blocks-composed-of-2-smaller-at-top-left', [
          ...new Array(2).fill({ w: 2, h: 1 }),
          { w: 2, h: 2 },
          { w: 4, h: 1 },
        ]),
      ];

    case 5:
      return [
        createLayout('1-col-on-left-side-and-4-equal-blocks', [{ w: 2, h: 2 }, ...new Array(4).fill({ w: 2, h: 1 })]),
        createLayout('1-col-in-middle-and-2-equal-blocks-on-each-side', [
          { w: 2, h: 1 },
          { w: 2, h: 2 },
          ...new Array(3).fill({ w: 2, h: 1 }),
        ]),
        createLayout('4-equal-blocks-and-1-col-on-right-side', [
          ...new Array(2).fill({ w: 2, h: 1 }),
          { w: 2, h: 2 },
          ...new Array(2).fill({ w: 2, h: 1 }),
        ]),
      ];
  }

  return [createLayout('6-equal-blocks', new Array(6).fill({ w: 2, h: 1 }))];
}
