import { ComponentPublicInstance, computed, Ref } from 'vue';

interface FocusableElement {
  blur: () => void;
  focus: (options?: FocusOptions) => void;
}

function isFocusableElement(target: unknown): target is FocusableElement {
  return typeof target === 'object' && target !== null && 'blur' in target && 'focus' in target;
}

function unrefFocusableElement(
  target: Ref<ComponentPublicInstance | HTMLOrSVGElement | null | undefined>
): FocusableElement | null {
  if (isFocusableElement(target.value)) {
    return target.value;
  }

  if (isFocusableElement((target.value as ComponentPublicInstance).$el)) {
    return (target.value as ComponentPublicInstance).$el;
  }

  return null;
}

export function useFocusable(target: Ref<ComponentPublicInstance | HTMLOrSVGElement | null | undefined>) {
  const focusableElement = computed(() => unrefFocusableElement(target));

  return {
    blur() {
      focusableElement.value?.blur();
    },
    focus(options?: FocusOptions) {
      focusableElement.value?.focus(options);
    },
  };
}
