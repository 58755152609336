import { Boundary } from '@/shared/components/state-selector/map-definition/MapDefinitionLoader';

export const adjustBoundaryToContainer = function (boundary: Boundary, width: number, height: number): Boundary {
  const adjustedBoundary = { ...boundary };
  const scale = height / width;

  if (width / boundary.width > height / boundary.height) {
    adjustedBoundary.width = boundary.height / scale;
    adjustedBoundary.x = boundary.x - (adjustedBoundary.width - boundary.width) / 2;
  } else {
    adjustedBoundary.height = boundary.width * scale;
    adjustedBoundary.y = boundary.y - (adjustedBoundary.height - boundary.height) / 2;
  }

  return adjustedBoundary;
};
