import { App, watch } from 'vue';
import VueMatomo from 'vue-matomo';

import router from '@/router';
import config from '@/shared/config';
import VueMatomoTagManager from '@/shared/plugins/vue-matomo-tag-manager';
import { useAppStore } from '@/store/modules/app';

declare global {
  interface Window {
    Piwik: { getAsyncTracker(): Promise<{ setUserId(userId: string): void; resetUserId(): void }> };
  }
}

export default function (app: App) {
  if (!config.tracking.url) {
    return;
  }

  const appStore = useAppStore();

  if (config.tracking.siteId) {
    app.use(VueMatomo, {
      host: config.tracking.url,
      siteId: config.tracking.siteId,
      router,
    });
  }

  if (config.tracking.containerId) {
    app.use(VueMatomoTagManager, {
      host: config.tracking.url,
      containerId: config.tracking.containerId,
    });
  }

  watch(
    () => appStore.isLoggedIn,
    async (isLoggedIn) => {
      if (config.tracking.siteId) {
        const matomo = await window.Piwik?.getAsyncTracker();

        if (!matomo) {
          return;
        }

        isLoggedIn && appStore.auth?.id ? matomo.setUserId(appStore.auth.id) : matomo.resetUserId();
      }

      if (config.tracking.containerId) {
        window._mtm = window._mtm || [];

        if (isLoggedIn && appStore.auth?.id) {
          window._mtm.push({ uid: appStore.auth.id });
        } else {
          window._mtm = window._mtm.filter((element) => {
            return !(typeof element === 'object' && 'uid' in element);
          });
        }
      }
    },
    { immediate: true }
  );
}
