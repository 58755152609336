import { Coordinate } from '@/shared/api/cartography';

import client from './axios';

export interface RouteOptions {
  readonly language?: string;
}

export interface SearchByPositionsResponse {
  readonly items: Location[];
}

export interface Location {
  readonly address: Address;
  readonly position: Coordinate;
}

export interface Address {
  readonly city: string;
  readonly zipCode: string;
  readonly country: string;
}

export async function searchByPosition(position: Coordinate, options?: RouteOptions): Promise<Location[]> {
  const { data } = await client.get<SearchByPositionsResponse>('locations', {
    params: {
      ...options,
      position: position.join(','),
    },
  });
  return data.items;
}
