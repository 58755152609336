import { DateTime } from 'luxon';

import translator from '@/shared/locale';

export function formatDate(
  date: DateTime,
  { format = 'dd/MM HH:mm', shortenFormat = 'dd/MM' }: { format?: string; shortenFormat?: string } = {}
): string {
  if (shortenFormat && date.toFormat('HH:mm') === '00:00') {
    return translator().d(date.toJSDate(), shortenFormat);
  }

  return translator().d(date.toJSDate(), format);
}
