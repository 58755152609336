import { get as localStorageGet, keys, set as localStorageSet } from '@/store/localStorage';

export function composeShortName(name: string) {
  return name.substring(0, 3).toUpperCase();
}

export function provideSearchShortName(id: string, searchName: string): string {
  const searchShortNames = localStorageGet<Record<string, string>>(keys.searchShortNames, {});

  if (!searchShortNames[id]) {
    searchShortNames[id] = composeShortName(searchName);
  }

  return searchShortNames[id];
}

export function setSearchShortName(id: string, shortName: string) {
  const searchShortNames = localStorageGet<Record<string, string>>(keys.searchShortNames, {});

  if (shortName !== searchShortNames[id]) {
    localStorageSet(keys.searchShortNames, {
      ...searchShortNames,
      [id]: shortName,
    });
  }
}

export function deleteSearchShortName(id: string) {
  const searchShortNames = localStorageGet<Record<string, string>>(keys.searchShortNames, {});

  if (id in searchShortNames) {
    delete searchShortNames[id];
    localStorageSet(keys.searchShortNames, searchShortNames);
  }
}
