import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';

import eventBus from '@/event-bus';
import { OfferNotFoundError, OfferRefreshRestrictionDelayNotElapsedError } from '@/shared/api/offer/types-api';
import config from '@/shared/config';
import {
  cloneOffer as apiCloneOffer,
  createOffer as apiCreateOffer,
  fetchDeletedOffer as apiFetchDeletedOffer,
  fetchOffer as apiFetchOffer,
  HistoryFilters,
  refreshOffer as apiRefreshOffer,
  removeOffer as apiRemoveOffer,
  updateOffer as apiUpdateOffer,
} from '@/shared/dataProviders/offer';
import { DeletedOffer, Offer, OfferCore, OfferInput } from '@/shared/types';

export type DisplayedOffer = Offer | DeletedOffer;

export function isOffer(offer: DisplayedOffer | null): offer is Offer {
  return !!offer && 'hasError' in offer;
}

export function isDeletedOffer(offer: DisplayedOffer | null): offer is DeletedOffer {
  return !!offer && 'deleteCause' in offer;
}

export const useOfferStore = defineStore('offer', () => {
  const isDetailLoading = ref(false);
  const displayedOfferId = ref('');
  const displayedOffer = ref<DisplayedOffer | null>(null);
  const inProcessOfferIds = ref<string[]>([]);
  const routingMapOffer = ref<OfferCore | null>(null);
  const consultationListOffer = ref<OfferCore | null>(null);
  const charterConfirmationFormOfferId = ref<OfferCore['id'] | null>(null);
  const statisticFormOffer = ref<OfferCore | null>(null);
  const historyFilters = ref<HistoryFilters | null>(null);

  const isConsultationListDisplayed = computed(() => !!consultationListOffer.value);
  const isRoutingMapDisplayed = computed(() => !!routingMapOffer.value);
  const isCharterConfirmationFormDisplayed = computed(() => !!charterConfirmationFormOfferId.value);
  const isStatisticFormDisplayed = computed(() => !!statisticFormOffer.value);
  const isHistoryModalDisplayed = computed(() => !!historyFilters.value);

  const traceabilityOffer = ref<OfferCore>();

  function displayOffer(id: string) {
    return display({
      id,
      provider: apiFetchOffer,
    });
  }

  function displayDeletedOffer(id: string) {
    return display({
      id,
      provider: apiFetchDeletedOffer,
    });
  }

  async function display({
    id,
    provider,
  }: {
    id: string;
    provider: (id: string) => Promise<DisplayedOffer | undefined>;
  }): Promise<void> {
    isDetailLoading.value = true;
    displayedOfferId.value = id;

    let offer: DisplayedOffer | null = null;

    try {
      offer = (await provider(id)) ?? null;
    } finally {
      displayedOffer.value = offer;
      isDetailLoading.value = false;
    }
  }

  function hideOffer() {
    displayedOfferId.value = '';
    displayedOffer.value = null;
  }

  async function createOffer(data: OfferInput) {
    await apiCreateOffer(data);
  }

  async function updateOffer(payload: { id: string; data: OfferInput }) {
    try {
      addOfferInProcess(payload.id);

      const offer = await apiUpdateOffer(payload.id, payload.data);

      if (displayedOfferId.value === offer.id) {
        displayedOffer.value = offer;
      }
    } finally {
      removeOfferInProcess(payload.id);
    }
  }

  async function refreshOffers(offers: OfferCore[]): Promise<{ notRefreshableOfferCount: number }> {
    const result = {
      notRefreshableOfferCount: 0,
    };

    if (!offers.length) {
      return result;
    }

    let needSync = false;

    await Promise.all(
      offers.map(async (offer: OfferCore) => {
        if (DateTime.now().diff(offer.lastActionDate, 'seconds').get('seconds') < config.refreshRestrictionDelay) {
          result.notRefreshableOfferCount += 1;

          return;
        }

        try {
          addOfferInProcess(offer.id);

          await apiRefreshOffer(offer.id);

          if (displayedOfferId.value === offer.id) {
            await hideOffer();
            await displayOffer(offer.id);
          }

          needSync = true;
        } catch (e) {
          if (e instanceof OfferNotFoundError) {
            needSync = true;

            if (displayedOfferId.value === offer.id) {
              await hideOffer();
            }

            return;
          }

          if (e instanceof OfferRefreshRestrictionDelayNotElapsedError) {
            result.notRefreshableOfferCount += 1;

            needSync = true;

            if (displayedOfferId.value === offer.id) {
              await hideOffer();
              await displayOffer(offer.id);
            }

            return;
          }
        } finally {
          removeOfferInProcess(offer.id);
        }
      })
    );

    if (needSync) {
      eventBus.$emit('offers.sync-required');
    }

    return result;
  }

  async function removeOffers({ ids, reason }: { ids: string[]; reason?: string }) {
    if (!ids.length) {
      return;
    }

    await Promise.all(
      ids.map(async (id) => {
        try {
          addOfferInProcess(id);

          await apiRemoveOffer(id, reason);

          if (displayedOfferId.value === id) {
            await hideOffer();
          }
        } finally {
          removeOfferInProcess(id);
        }
      })
    );

    eventBus.$emit('offers.sync-required');
  }

  async function repostOffers(offers: DeletedOffer[]) {
    await Promise.all(
      offers.map(async (offer) => {
        try {
          addOfferInProcess(offer.id);
          await apiCloneOffer(offer);
        } finally {
          removeOfferInProcess(offer.id);
        }
      })
    );
  }

  function addOfferInProcess(id: string): void {
    if (!inProcessOfferIds.value.includes(id)) {
      inProcessOfferIds.value.push(id);
    }
  }
  function removeOfferInProcess(id: string): void {
    const index = inProcessOfferIds.value.indexOf(id);

    if (index > -1) {
      inProcessOfferIds.value.splice(index, 1);
    }
  }

  function showRoutingMap(offer: OfferCore): void {
    routingMapOffer.value = offer;
  }
  function hideRoutingMap(): void {
    routingMapOffer.value = null;
  }

  function showConsultationList(offer: OfferCore): void {
    consultationListOffer.value = offer;
  }
  function hideConsultationList(): void {
    consultationListOffer.value = null;
  }

  function showCharterConfirmationForm(offerId: OfferCore['id']): void {
    charterConfirmationFormOfferId.value = offerId;
  }
  function hideCharterConfirmationForm(): void {
    charterConfirmationFormOfferId.value = null;
  }

  function showStatisticForm(offer: OfferCore): void {
    statisticFormOffer.value = offer;
  }
  function hideStatisticForm(): void {
    statisticFormOffer.value = null;
  }

  function showHistoryModal(filters: HistoryFilters): void {
    historyFilters.value = filters;
  }

  function hideHistoryModal(): void {
    historyFilters.value = null;
  }

  function showTraceabilityRequestModal(offer: OfferCore) {
    traceabilityOffer.value = offer;
  }

  function hideTraceabilityRequestModal(): void {
    traceabilityOffer.value = undefined;
  }

  watch(displayedOfferId, () => {
    hideRoutingMap();
  });

  return {
    inProcessOfferIds,
    isDetailLoading,

    displayedOffer,
    displayedOfferId,
    displayOffer,
    displayDeletedOffer,
    hideOffer,
    createOffer,
    updateOffer,
    refreshOffers,
    removeOffers,
    repostOffers,

    consultationListOffer,
    isConsultationListDisplayed,
    showConsultationList,
    hideConsultationList,

    routingMapOffer,
    isRoutingMapDisplayed,
    showRoutingMap,
    hideRoutingMap,

    charterConfirmationFormOfferId,
    isCharterConfirmationFormDisplayed,
    showCharterConfirmationForm,
    hideCharterConfirmationForm,

    statisticFormOffer,
    isStatisticFormDisplayed,
    showStatisticForm,
    hideStatisticForm,

    historyFilters,
    isHistoryModalDisplayed,
    showHistoryModal,
    hideHistoryModal,

    traceabilityOffer,
    showTraceabilityRequestModal,
    hideTraceabilityRequestModal,
  };
});
