import { fetchPostProfiles } from '@/shared/api/offer/read';
import { Profile } from '@/shared/api/offer/types';

let profiles: Record<string, string>;

const defaults: PostProfileType[] = [];

export type PostProfileType = keyof typeof profiles;

export function isPostProfileType(code: string): code is PostProfileType {
  if (!profiles) {
    throw 'Unable to type guard post profile code if enum is not loaded';
  }

  return !!code && !!profiles[code];
}

export function isDefaultProfile(code: string): boolean {
  return isPostProfileType(code) && defaults.includes(code);
}

export async function list(): Promise<Record<string, string>> {
  if (!profiles) {
    const apiProfiles = await fetchPostProfiles();

    profiles = {};
    apiProfiles.forEach(({ key, value, isDefault }: Profile) => {
      profiles[key as PostProfileType] = value;

      if (isDefault) {
        defaults.push(key);
      }
    });
  }

  return profiles;
}
