/* eslint-disable @typescript-eslint/no-explicit-any */

type Procedure = (...args: any[]) => void;

export default function throttle<F extends Procedure>(func: F, limit: number) {
  let inThrottle = false;

  return function (...args: Parameters<F>): void {
    if (!inThrottle) {
      inThrottle = true;
      func(...args);
      setTimeout(() => (inThrottle = false), limit);
    }
  };
}
