export enum ParameterType {
  SHARED_OFFER = 'SHARED_OFFER',
  OFFER_DELETE_CAUSE = 'OFFER_DELETE_CAUSE',
}

export enum SharedOfferValue {
  DISABLED = '0',
  VIEW = '1',
  MANAGE = '2',
}

export enum OfferDeleteCauseValue {
  DISABLED = '0',
  ENABLED = '1',
}

export type ParameterMap = {
  [ParameterType.SHARED_OFFER]: SharedOfferValue;
  [ParameterType.OFFER_DELETE_CAUSE]: OfferDeleteCauseValue;
};

export interface Parameter<T extends ParameterType> {
  id: T;
  value: ParameterMap[T];
}

export interface UserSettings {
  maxSearchNumberAllowed: number;
}
