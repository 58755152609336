import { TranslatedMessage } from '@/shared/locale';
import { SelectOption } from '@/shared/types';

export function enumToOptionList<T extends string = string>(
  input: Record<T, TranslatedMessage | string> | Partial<Record<T, TranslatedMessage | string>>
): SelectOption<T>[] {
  return (Object.keys(input) as T[]).map(
    (key: T): SelectOption<T> => ({ value: key, text: (input[key] as TranslatedMessage | string).toString() })
  );
}

export function sortOptionListByValue<T extends string = string>(input: SelectOption<T>[]): SelectOption<T>[] {
  return input.sort((a: SelectOption<T>, b: SelectOption<T>) =>
    a.value.toLocaleLowerCase() < b.value.toLocaleLowerCase() ? -1 : 1
  );
}

export function sortOptionListByText<T extends string = string>(input: SelectOption<T>[]): SelectOption<T>[] {
  return input.sort((a: SelectOption<T>, b: SelectOption<T>) => a.text.localeCompare(b.text));
}

export function toLengthLabel(minLength: number, maxLength: number): string {
  if (minLength && minLength == maxLength) {
    return `${minLength} m`;
  } else if (minLength && maxLength) {
    return `${minLength} m → ${maxLength} m`;
  } else if (minLength) {
    return `> ${minLength} m`;
  } else if (maxLength) {
    return `< ${maxLength} m`;
  }

  return '';
}

export function toMaxVolumeLabel(maxVolume: number): string {
  if (maxVolume) {
    return `< ${maxVolume} m³`;
  }

  return '';
}

export function toWeightLabel(minWeight: number, maxWeight: number): string {
  if (minWeight && minWeight == maxWeight) {
    return `${minWeight} T`;
  } else if (minWeight && maxWeight) {
    return `${minWeight} T → ${maxWeight} T`;
  } else if (minWeight) {
    return `> ${minWeight} T`;
  } else if (maxWeight) {
    return `< ${maxWeight} T`;
  }

  return '';
}

export function toZonesLabel(zones: string[]): string {
  const unserializedZones: Record<string, string[]> = {};

  zones.forEach((zone: string) => {
    zone = zone.trim();

    const [country, state] = [zone.substring(0, 2), zone.substring(2)];

    // if (!(country in this.countries) || !state) {
    //   return;
    // }

    if (!unserializedZones[country] || state === 'XX') {
      unserializedZones[country] = [];
    }

    if (unserializedZones[country].includes('XX')) {
      return;
    }

    unserializedZones[country].push(state);
  });

  const label: string[] = [];
  for (const countryId of Object.keys(unserializedZones)) {
    label.push(countryId);

    if (unserializedZones[countryId].includes('XX')) {
      // @todo Translate
      label.push('Tous');
    } else {
      label.push(unserializedZones[countryId].join('-'));
    }
  }

  return label.join(' ');
}
