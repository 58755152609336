import { watch } from 'vue';

import { useSearchStore } from '@/store/modules/search';

let searchTimerInterval = 0;
let searchTimerId: number;

export function watchSearches({ interval = 60, immediate = false }: { interval?: number; immediate?: boolean } = {}) {
  searchTimerInterval = interval * 1000;
  unwatchSearches();

  const handler = async () => {
    try {
      await useSearchStore().fetchAlerts();
    } finally {
      if (searchTimerId) {
        searchTimerId = setTimeout(handler, searchTimerInterval);
      }
    }
  };

  searchTimerId = setTimeout(handler, immediate ? 0 : searchTimerInterval);
}

export function unwatchSearches() {
  if (searchTimerId) {
    clearTimeout(searchTimerId);

    searchTimerId = 0;
  }
}

const watched: Record<string, CallableFunction> = {};

export function watchSearchAlerts(searchId: string, callable: CallableFunction) {
  if (watched[searchId]) {
    unwatchSearchAlerts(searchId);
  }

  const searchStore = useSearchStore();

  watched[searchId] = watch(
    () => searchStore.alerts[searchId],
    (alert) => {
      if (alert?.newOffersCount > 0) {
        callable(alert);
      }
    },
    { immediate: true }
  );
}

export function unwatchSearchAlerts(searchId: string) {
  if (watched[searchId]) {
    watched[searchId]();
    delete watched[searchId];
  }
}
