import { VehicleCharacteristic } from '@/shared/dataProviders/directory';
import translator, { TranslatedMessage } from '@/shared/locale';

let values: Record<VehicleCharacteristic, TranslatedMessage>;

export function list(): Record<VehicleCharacteristic, TranslatedMessage> {
  return load();
}

export function label(key: VehicleCharacteristic): TranslatedMessage | undefined {
  return load()[key];
}

function load(): Record<VehicleCharacteristic, TranslatedMessage> {
  if (!values) {
    values = {
      [VehicleCharacteristic.Brackets]: translator().t('Équerres'),
      [VehicleCharacteristic.Crane]: translator().t('Grue'),
      [VehicleCharacteristic.FortyFourTons]: translator().t('44T'),
      [VehicleCharacteristic.MovingBottom]: translator().t('Fond mouvant'),
      [VehicleCharacteristic.Palettisable]: translator().t('Palettisable'),
      [VehicleCharacteristic.SemiTrailer]: translator().t('Semi-remorque'),
      [VehicleCharacteristic.Straps]: translator().t('Sangles'),
      [VehicleCharacteristic.Tailgate]: translator().t('Hayon'),
      [VehicleCharacteristic.TrailerTruck]: translator().t('Camion remorque'),
      [VehicleCharacteristic.TrolleyLoader]: translator().t('Chariot embarqué'),
    };
  }

  return values;
}
