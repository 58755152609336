import { AbortOptions } from '@/shared/dataProviders/common';
import { B2PWebError } from '@/shared/dataProviders/common/b2pweb';
import { RestClient } from '@/shared/dataProviders/common/RestClient';

import { Enum, EnumDataProvider } from '..';
import { B2PWebEnumResponse } from '.';

export class B2PWebEnumDataProvider implements EnumDataProvider {
  constructor(private readonly client: RestClient) {}

  async fetchExchanges(options?: AbortOptions): Promise<Enum<string, string>[]> {
    const response = await this.client.get<B2PWebEnumResponse<string, string>, B2PWebError>('/enum/exchanges', {
      signal: options?.signal,
    });

    if (!response.isOk) {
      throw new Error(response.data.message);
    }

    return Object.values(response.data);
  }
}
