import { localize, setLocale } from '@vee-validate/i18n';
import default_en from '@vee-validate/i18n/dist/locale/en.json';
import default_fr from '@vee-validate/i18n/dist/locale/fr.json';
import { email, required } from '@vee-validate/rules';
import { configure, defineRule } from 'vee-validate';

import { translations as en_translations } from '@/shared/locale/en';
import { translations as fr_translations } from '@/shared/locale/fr';
import { useAppStore } from '@/store/modules/app';

export default function () {
  configure({
    generateMessage: localize({
      en: { ...default_en, ...{ messages: en_translations.validations } },
      fr: { ...default_fr, ...{ messages: fr_translations.validations } },
    }),
  });

  defineRule('required', required);
  defineRule('email', email);

  setLocale(useAppStore().locale);
}
