import { isEqual } from 'lodash-es';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import eventBus from '@/event-bus';
import { Collection, fetchSearchAlerts, GetSearchOffersOptions, SearchAlert } from '@/shared/api/search';
import { createSearch as apiCreateSearch, getSearches, removeSearch } from '@/shared/dataProviders/search';
import { getSearchOffers as apiGetSearchOffers } from '@/shared/dataProviders/search-offer';
import { Search, SearchInput, SearchOffer } from '@/shared/types';
import { useSearchGridStore } from '@/store/modules/search-grid';
import { useSettingsStore } from '@/store/modules/settings';

export const useSearchStore = defineStore('search', () => {
  const alerts = ref<Record<string, SearchAlert>>({});
  const isListLoading = ref(false);
  const inProcessSearchIds = ref<Set<string>>(new Set());
  const searches = ref<Search[]>([]);
  const lastAccessDates = ref<Record<string, DateTime>>({});

  async function fetchSearches() {
    try {
      isListLoading.value = true;
      setSearches((await getSearches({ enabled: true })).items);

      syncAlerts();
      syncLastAccessDates();

      const searchGridStore = useSearchGridStore();

      if (!searches.value.length) {
        searchGridStore.set([]);
        return;
      }

      const filteredSearchIds = searchGridStore.activeSearchIds.filter(
        (searchId) => searches.value.findIndex(({ id }) => id === searchId) > -1
      );

      if (filteredSearchIds.length) {
        searchGridStore.set(filteredSearchIds);
      } else {
        searchGridStore.set([searches.value[0].id]);
      }
    } finally {
      isListLoading.value = false;
    }
  }

  async function createSearch(data: SearchInput) {
    const search = await apiCreateSearch(data);

    const searchGridStore = useSearchGridStore();
    if (searchGridStore.activeSearchIds.length < 2) {
      searchGridStore.set([search.id]);
    }

    await fetchSearches();
  }

  async function updateSearch(payload: { id: string; data: SearchInput }) {
    if (inProcessSearchIds.value.has(payload.id)) {
      return;
    }

    try {
      addSearchInProcess(payload.id);

      await removeSearch(payload.id);

      const createdSearch = await apiCreateSearch(payload.data);

      setSearches(searches.value.map((search: Search) => (search.id === payload.id ? createdSearch : search)));
      syncAlerts();
      syncLastAccessDates();

      useSearchGridStore().onSearchUpdated({
        previousId: payload.id,
        newId: createdSearch.id,
      });
      useSettingsStore().onSearchUpdated({
        previousId: payload.id,
        newId: createdSearch.id,
      });
    } finally {
      removeSearchInProcess(payload.id);
    }
  }

  async function removeSearches(ids: string[]) {
    ids = ids.filter((id) => !inProcessSearchIds.value.has(id));

    if (!ids.length) {
      return;
    }

    const searchGridStore = useSearchGridStore();

    await Promise.all(
      ids.map(async (id) => {
        try {
          addSearchInProcess(id);

          await removeSearch(id);
          searchGridStore.remove(id);

          setSearches(searches.value.filter((search: Search) => search.id !== id));
          removeAlert(id);
          removeLastAccessDate(id);

          eventBus.$emit('search.search-deleted', {
            searchId: id,
          });
        } finally {
          removeSearchInProcess(id);
        }
      })
    );

    if (!searchGridStore.activeSearchIds.length && searches.value.length) {
      await searchGridStore.set([searches.value[0].id]);
    }
  }

  function getSearchOffers({
    searchId,
    options,
  }: {
    searchId: string;
    options?: GetSearchOffersOptions;
  }): Promise<Collection<SearchOffer>> {
    const collection = apiGetSearchOffers({ searchId, options });

    if (options?.page === 1) {
      resetAlert(searchId);
      resetLastAccessDate(searchId);
    }

    return collection;
  }

  function setSearches(searchesToProcess: Search[]) {
    const newSearches: Search[] = [];

    // Update searches
    searchesToProcess.forEach((search: Search) => {
      const searchToUpdate = searches.value.find(({ id }: Search) => id === search.id);

      if (!searchToUpdate) {
        newSearches.push(search);
        return;
      }

      if (!isEqual(search, searchToUpdate)) {
        Object.assign(searchToUpdate, search);
      }
    });

    // Add new searches
    if (newSearches.length) {
      searches.value.unshift(...newSearches);
    }

    // Remove old searches
    if (searches.value.length !== searchesToProcess.length) {
      const newSearchIds = searchesToProcess.map(({ id }) => id);
      searches.value = searches.value.filter(({ id }) => newSearchIds.includes(id));
    }
  }

  async function fetchAlerts() {
    if (!searches.value.length) {
      return;
    }

    const currentLastAccessDates = { ...lastAccessDates.value };

    const alertsToProcess = await fetchSearchAlerts(
      searches.value.map((search) => ({
        searchId: search.id,
        since: currentLastAccessDates[search.id],
      }))
    );

    for (const { searchId, newOffersCount } of alertsToProcess) {
      // Update only when access date has not changed in the meantime
      if (
        searchId in alerts.value &&
        lastAccessDates.value[searchId] === currentLastAccessDates[searchId] &&
        newOffersCount !== alerts.value[searchId].newOffersCount
      ) {
        alerts.value[searchId] = {
          searchId,
          newOffersCount,
        };

        notifyNewOffers(searchId, newOffersCount);
      }
    }
  }

  function syncAlerts(): void {
    const syncedAlerts: Record<string, SearchAlert> = {};

    for (const search of searches.value) {
      syncedAlerts[search.id] = alerts.value[search.id] ?? {
        searchId: search.id,
        newOffersCount: 0,
      };
    }

    alerts.value = syncedAlerts;
  }

  function removeAlert(searchId: Search['id']): void {
    delete alerts.value[searchId];
  }

  function resetAlert(searchId: Search['id']): void {
    alerts.value[searchId] = {
      searchId,
      newOffersCount: 0,
    };
  }

  function syncLastAccessDates(): void {
    const syncedLastAccessDates: Record<string, DateTime> = {};

    for (const search of searches.value) {
      syncedLastAccessDates[search.id] = lastAccessDates.value[search.id] ?? DateTime.local();
    }

    lastAccessDates.value = syncedLastAccessDates;
  }

  function removeLastAccessDate(searchId: Search['id']): void {
    delete lastAccessDates.value[searchId];
  }

  function resetLastAccessDate(searchId: string): void {
    lastAccessDates.value[searchId] = DateTime.local();
  }

  function addSearchInProcess(id: string) {
    inProcessSearchIds.value.add(id);
  }

  function removeSearchInProcess(id: string) {
    inProcessSearchIds.value.delete(id);
  }

  function notifyNewOffers(searchId: Search['id'], newOffersCount: number): void {
    const search = searches.value.find((search) => search.id === searchId);

    if (!search) {
      return;
    }

    eventBus.$emit('search.alerts.new-offers-received', {
      search,
      count: newOffersCount,
    });
  }

  return {
    alerts,
    isListLoading,
    inProcessSearchIds,
    searches,

    fetchSearches,
    createSearch,
    updateSearch,
    removeSearches,
    getSearchOffers,
    fetchAlerts,
  };
});
