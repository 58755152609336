import config from '@/shared/config';
import { Collection, Contact, CustomerContact } from '@/shared/dataProviders/common';
import { B2PWebCollection, B2PWebContact, B2PWebCustomerContact } from '@/shared/dataProviders/common/b2pweb';

export function mapToCollection<Source, Target>(
  source: B2PWebCollection<Source>,
  mapper: (item: Source) => Target
): Collection<Target> {
  return {
    items: source.items.map((item) => mapper(item)),
    count: source.pagination.pageCount,
    totalCount: source.pagination.totalCount,
    page: source.pagination.currentPage,
    pageCount: Math.ceil(source.pagination.totalCount / source.pagination.limit),
    perPage: source.pagination.limit,
  };
}

export function mapToContact(source: B2PWebContact): Contact {
  return {
    name: source.name,
    email: source.email ?? undefined,
    phone: source.phone ?? undefined,
    mobile: source.mobile ?? undefined,
    fax: source.fax ?? undefined,
    languages: source.languages,
  };
}

export function mapToCustomerContact(source: B2PWebCustomerContact): CustomerContact {
  return {
    id: source.id,
    name: source.name,
    logo: source.logo ? formatLogoUrl(source.logo) : undefined,
  };
}

function formatLogoUrl(path: string): string {
  const baseUrl = config.urls.customerLogo.endsWith('/')
    ? config.urls.customerLogo.slice(0, -1)
    : config.urls.customerLogo;

  path = path.startsWith('/') ? path.slice(1, path.length) : path;

  return `${baseUrl}/${path}`;
}
