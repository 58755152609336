import { getAuthorization } from '@/shared/api/event-stream';
import config from '@/shared/config';
import EventStream, { AuthorizationAdapter, RequestAuthorization } from '@/shared/event-stream';

export interface SearchPoolCreatedPayload {
  readonly id: string;
}

interface EventTypeMap {
  'search/pool/created': SearchPoolCreatedPayload;
}

class B2PWebAuthorizationAdapter implements AuthorizationAdapter {
  async getAuthorization(signal: AbortSignal): Promise<RequestAuthorization> {
    const token = await getAuthorization({ signal });

    return {
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
}

const stream = new EventStream<EventTypeMap>(config.eventStream.subscriptionUrl, new B2PWebAuthorizationAdapter());

export default stream;
