import translator, { TranslatedMessage } from '@/shared/locale';

export const TAILGATE = 'tailgate';
export const STRAPS = 'straps';
export const BRACKETS = 'brackets';
export const UNDER_BAR_2_70 = '2.70 under bar';
export const TRAILER_TRUCK = 'trailer truck';
export const CARRIER = 'carrier';
export const PALLETS_EXCHANGE = 'pallets exchange';
export const QUALIMAT_GMP = 'qualimat gmp';

export type NoteTagType =
  | typeof TAILGATE
  | typeof STRAPS
  | typeof BRACKETS
  | typeof UNDER_BAR_2_70
  | typeof TRAILER_TRUCK
  | typeof CARRIER
  | typeof PALLETS_EXCHANGE
  | typeof QUALIMAT_GMP;

let labels!: Record<NoteTagType, TranslatedMessage>;

function loadEnum(): { labels: typeof labels } {
  if (!labels) {
    labels = {
      [TAILGATE]: translator().t('Hayon'),
      [STRAPS]: translator().t('Sangles'),
      [BRACKETS]: translator().t('Equerres'),
      [UNDER_BAR_2_70]: translator().t('2,70 sous barre'),
      [TRAILER_TRUCK]: translator().t('Camion remorque'),
      [CARRIER]: translator().t('Porteur'),
      [PALLETS_EXCHANGE]: translator().t('Palette échange'),
      [QUALIMAT_GMP]: translator().t('Qualimat GMP'),
    };
  }

  return { labels };
}

export function isNoteTagType(code: string): code is NoteTagType {
  return (
    !!code &&
    [TAILGATE, STRAPS, BRACKETS, UNDER_BAR_2_70, TRAILER_TRUCK, CARRIER, PALLETS_EXCHANGE, QUALIMAT_GMP].indexOf(code) >
      -1
  );
}

export function list(): Record<NoteTagType, TranslatedMessage> {
  return loadEnum().labels;
}

export function label(code: string): TranslatedMessage | undefined {
  if (isNoteTagType(code)) {
    return loadEnum().labels[code];
  }

  return undefined;
}
