import { createI18n, I18n, TranslateResult, VueI18n } from 'vue-i18n';

import { datetimeFormats as en_datetimeFormats, translations as en_translations } from '@/shared/locale/en';
import { datetimeFormats as fr_datetimeFormats, translations as fr_translations } from '@/shared/locale/fr';
import { useAppStore } from '@/store/modules/app';

export type TranslatedMessage = TranslateResult;

let instance: I18n;

export function createPlugin() {
  if (!instance) {
    instance = createI18n({
      locale: useAppStore().locale,
      fallbackLocale: 'fr',
      datetimeFormats: {
        en: en_datetimeFormats,
        fr: fr_datetimeFormats,
      },
      messages: {
        en: en_translations,
        fr: fr_translations,
      },
    });
  }

  return instance;
}

export default function translator() {
  return createPlugin().global as VueI18n;
}
