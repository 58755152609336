export type Currency = 'EUR';

export interface Price {
  value: number;
  currency: Currency;
}

export function fromApi(price: Price): Price {
  return price;
}
