import { AbortOptions } from '@/shared/dataProviders/common';
import { B2PWebError, handleB2PWebResponse } from '@/shared/dataProviders/common/b2pweb';
import { RestClient } from '@/shared/dataProviders/common/RestClient';
import {
  FetchThirdPartyVehiclesFilters,
  ThirdPartyVehicleSearch,
  VehicleSearchAlert,
  VehicleSearchDataProvider,
} from '@/shared/dataProviders/vehicle';
import {
  B2PWebThirdPartyVehicleSearch,
  B2PWebThirdPartyVehicleSearchInput,
  B2PWebVehicleSearchAlert,
  mapToB2PWebVehicleSearchInput,
  mapToVehicleSearch,
  mapToVehicleSearchAlert,
} from '@/shared/dataProviders/vehicle/b2pweb';

export class B2PWebVehicleSearchDataProvider implements VehicleSearchDataProvider {
  constructor(private readonly client: RestClient) {}

  async count(options?: AbortOptions): Promise<number> {
    return handleB2PWebResponse(
      await this.client.get<number, B2PWebError>('/savedSearch/count', {
        signal: options?.signal,
      })
    );
  }

  async create(name: string, filters: FetchThirdPartyVehiclesFilters): Promise<void> {
    return handleB2PWebResponse(
      await this.client.post<void, B2PWebThirdPartyVehicleSearchInput, B2PWebError>(
        `/savedSearch`,
        mapToB2PWebVehicleSearchInput(name, filters)
      )
    );
  }

  async fetchAll(options?: AbortOptions): Promise<ThirdPartyVehicleSearch[]> {
    const { items } = handleB2PWebResponse(
      await this.client.get<{ items: B2PWebThirdPartyVehicleSearch[] }, B2PWebError>('/savedSearch', {
        signal: options?.signal,
      })
    );
    return items.map((item) => mapToVehicleSearch(item));
  }

  async removeAll(ids: string[], options?: AbortOptions): Promise<void> {
    await Promise.all(ids.map((id) => this.remove(id, options)));
  }

  async remove(id: string, options?: AbortOptions): Promise<void> {
    return handleB2PWebResponse(
      await this.client.delete<void, B2PWebError>(`/savedSearch/${id}`, {
        signal: options?.signal,
      })
    );
  }

  async fetchAlerts(options?: AbortOptions): Promise<VehicleSearchAlert[]> {
    const items = handleB2PWebResponse(
      await this.client.get<B2PWebVehicleSearchAlert[], B2PWebError>('/savedSearch/newVehiclesCount', {
        signal: options?.signal,
      })
    );
    return items.map((item) => mapToVehicleSearchAlert(item));
  }
}
