import Printd from 'printd';

interface PrintOptions {
  includeStylesheets?: boolean;
  title?: string;
}

export function print(el: HTMLElement, options?: PrintOptions) {
  // It's faster for the print method to receive all the styles as a string
  let stylesAsString = '';

  if (options?.includeStylesheets) {
    for (const styleSheet of document.styleSheets) {
      try {
        for (const cssRule of styleSheet.cssRules) {
          stylesAsString += cssRule.cssText;
        }
      } catch {
        // Ignore stylesheets not accessible
      }
    }
  }

  new Printd().print(el, [stylesAsString], undefined, ({ iframe, launchPrint }) => {
    try {
      if (options?.title && iframe.contentDocument) {
        iframe.contentDocument.title = options.title;
      }

      launchPrint();
    } finally {
      iframe.parentNode?.removeChild(iframe);
    }
  });
}
