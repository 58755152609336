import { VehicleCertification } from '@/shared/dataProviders/directory';
import translator, { TranslatedMessage } from '@/shared/locale';

let values: Record<VehicleCertification, TranslatedMessage>;

export function list(): Record<VehicleCertification, TranslatedMessage> {
  return load();
}

export function label(key: VehicleCertification): TranslatedMessage | undefined {
  return load()[key];
}

function load(): Record<VehicleCertification, TranslatedMessage> {
  if (!values) {
    values = {
      [VehicleCertification.ADR]: translator().t('ADR'),
      [VehicleCertification.ISO]: translator().t('ISO'),
      [VehicleCertification.OEA]: translator().t('OEA'),
      [VehicleCertification.Qualimat]: translator().t('Qualimat'),
    };
  }

  return values;
}
