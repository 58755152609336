import EventHandler from '@/shared/components/state-selector/event/handlers/EventHandler';

export default class StateSelectionHandler extends EventHandler {
  constructor() {
    super();

    this.onClick = this.onClick.bind(this);
  }

  registerHook(): void {
    this.manager.root.addEventListener('click', this.onClick);
  }

  unregisterHook(): void {
    this.manager.root.removeEventListener('click', this.onClick);
  }

  onClick(event: MouseEvent): void {
    const state = (event.target as SVGElement)?.closest('.state');

    if (!state || event.detail > 2) {
      return;
    }

    if (!this.manager.isAllowed(event)) {
      return;
    }

    state.dispatchEvent(new CustomEvent('state-click', { bubbles: true }));

    if (event.detail > 1) {
      state.dispatchEvent(new CustomEvent('group-click', { bubbles: true }));
    }

    this.manager.record(event);
  }
}
