import { CircularBuffer } from '@/shared/circular-buffer';
import window from '@/shared/window';

function checkSupport(): boolean {
  return 'Notification' in window && isSecureContext();
}

function isSecureContext(): boolean {
  return 'isSecureContext' in window
    ? window.isSecureContext
    : window.location.protocol === 'https:' || isLocalhost(window.location.hostname);
}

function isLocalhost(hostname: string): boolean {
  return (
    hostname === 'localhost' || hostname === '::1' || hostname.endsWith('.localhost') || hostname.startsWith('127.')
  );
}

const isSupported = checkSupport();

interface PendingNotification {
  title: string;
  options?: NotificationOptions;
  resolve: (notification?: Notification) => void;
  reject: (reason?: unknown) => void;
}

interface NotificationManagerOptions {
  readonly bufferCapacity: number;
}

export default class NotificationManager {
  private static NOTIFICATION_TIMEOUT = 4000;

  private currentNotification?: Notification;
  private currentNotificationTimeoutID?: number;
  private pendingNotifications: CircularBuffer<PendingNotification>;

  constructor(options: NotificationManagerOptions) {
    this.pendingNotifications = new CircularBuffer(options.bufferCapacity);
  }

  get isSupported(): boolean {
    return isSupported;
  }

  get permission(): NotificationPermission {
    return isSupported ? Notification.permission : 'denied';
  }

  async requestPermission(): Promise<NotificationPermission> {
    if (this.permission !== 'default') {
      return this.permission;
    }

    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      this.processNext();
    } else if (permission === 'denied') {
      this.clear();
    }

    return permission;
  }

  notify(title: string, options?: NotificationOptions): Promise<Notification | undefined> {
    return new Promise((resolve, reject) => {
      if (this.permission === 'denied') {
        this.clear();

        resolve(undefined);

        return;
      }

      this.pendingNotifications.enqueue({ title, options, resolve, reject });

      if (this.currentNotification || this.permission === 'default') {
        return;
      }

      this.processNext();
    });
  }

  private processNext(): void {
    const data = this.pendingNotifications.dequeue();

    if (!data) {
      return;
    }

    try {
      this.currentNotification = new Notification(data.title, data.options);

      this.currentNotification.addEventListener('close', () => {
        this.currentNotification = undefined;

        clearTimeout(this.currentNotificationTimeoutID);

        if (this.permission === 'granted') {
          this.processNext();
        } else if (this.permission === 'denied') {
          this.clear();
        }
      });

      this.currentNotificationTimeoutID = setTimeout(
        () => this.currentNotification?.close(),
        NotificationManager.NOTIFICATION_TIMEOUT
      );

      data.resolve(this.currentNotification);
    } catch (e) {
      data.reject(e);
    }
  }

  clear(): void {
    this.pendingNotifications.forEach((pending) => pending.resolve());

    this.currentNotification?.close();
    this.currentNotification = undefined;
  }
}
