import { ref } from 'vue';

import { AbortOptions } from '@/shared/dataProviders/common';
import { useEnumDataProvider } from '@/shared/dataProviders/enum';

const exchanges = ref<Record<string, string>>();

export async function load(options?: AbortOptions): Promise<void> {
  if (exchanges.value) {
    return;
  }

  const values: Record<string, string> = {};

  for (const item of await useEnumDataProvider().fetchExchanges(options)) {
    values[item.key] = item.value;
  }

  exchanges.value = values;
}

export function list(): Record<string, string> {
  return exchanges.value ?? {};
}

export function label(key: string): string | undefined {
  return exchanges.value?.[key];
}
