import { DateTime, DurationUnit } from 'luxon';

import translator, { TranslatedMessage } from '@/shared/locale';

const RELATIVE_DATE_UNITS: DurationUnit[] = ['days', 'hours', 'minutes', 'seconds'];
const RELATIVE_DATE_UNITS_TRANSLATIONS: Record<string, (count: number) => TranslatedMessage> = {
  days: (count) => translator().t('{days} j', { days: count }),
  hours: (count) => translator().t('{hours} h', { hours: count }),
  minutes: (count) => translator().t('{minutes} min', { minutes: count }),
  seconds: () => translator().t('< {minutes} min', { minutes: 1 }),
};

function calculateRelativeDate(date: DateTime): { isPast: boolean; value: number; unit: DurationUnit } {
  for (const unit of RELATIVE_DATE_UNITS) {
    const count = date.diffNow(unit).get(unit);

    if (Math.abs(count) >= 1) {
      return { isPast: count <= 0, value: Math.floor(Math.abs(count)), unit };
    }
  }

  return { isPast: true, value: 0, unit: 'seconds' };
}

export function formatDateAsRelative(date: DateTime): string {
  const relativeDate = calculateRelativeDate(date);

  return translator().t(relativeDate.isPast ? 'il y a {date}' : 'dans {date}', {
    date: RELATIVE_DATE_UNITS_TRANSLATIONS[relativeDate.unit](relativeDate.value),
  }) as string;
}
