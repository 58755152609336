import { DateTime } from 'luxon';

import { formatDate } from '@/shared/utils';

export function formatPeriod(dateStart?: DateTime | null, dateEnd?: DateTime | null): string {
  if (dateStart && dateStart?.toFormat('dd/MM HH:mm') === dateEnd?.toFormat('dd/MM HH:mm')) {
    return formatDate(dateStart);
  }

  if (dateStart && dateEnd) {
    if (dateStart.toFormat('YYYY/dd/MM') === dateEnd.toFormat('YYYY/dd/MM')) {
      return `${formatDate(dateStart)} → ${formatDate(dateEnd, { format: 'HH:mm' })}`;
    }

    return `${formatDate(dateStart)} → ${formatDate(dateEnd)}`;
  }

  if (dateStart) {
    return `> ${formatDate(dateStart)}`;
  }

  if (dateEnd) {
    return `< ${formatDate(dateEnd)}`;
  }

  return '';
}
