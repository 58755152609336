import source from 'i18n-iso-countries';

import { useAppStore } from '@/store/modules/app';

export type Country = string;

export const COUNTRY_FR = 'FR';

export const EUROPE = [
  'AL',
  'AT',
  'BA',
  'BE',
  'BG',
  'BY',
  'CH',
  'CY',
  'CZ',
  'DE',
  'DK',
  'DZ',
  'EE',
  'ES',
  'FI',
  COUNTRY_FR,
  'GB',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MA',
  'MD',
  'ME',
  'MK',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'RS',
  'RU',
  'SE',
  'SI',
  'SK',
  'TN',
  'TR',
  'UA',
];

export function isCountry(code: string): code is Country {
  return !!source.getName(code, useAppStore().locale);
}

export function list(): Record<string, string> {
  return source.getNames(useAppStore().locale);
}

export function availableCountryList(): Record<string, string> {
  return EUROPE.reduce((list: Record<string, string>, code: string) => {
    list[code] = source.getName(code, useAppStore().locale);
    return list;
  }, {});
}

export function label(code: string): string | undefined {
  return source.getName(code, useAppStore().locale) || undefined;
}
