import { computed, useAttrs } from 'vue';

const onRE = /^on[^a-z]/;
const isOn = (key: string) => onRE.test(key);

export function useUnpackedAttrs() {
  const packedAttrs = useAttrs();

  const attrs = computed(() => {
    const attrs: Record<string, unknown> = {};

    for (const key of Object.keys(packedAttrs)) {
      if (!isOn(key)) {
        attrs[key] = packedAttrs[key];
      }
    }

    return attrs;
  });

  const listeners = computed(() => {
    const listeners: Record<string, unknown> = {};

    for (const key of Object.keys(packedAttrs)) {
      if (isOn(key)) {
        listeners[key] = packedAttrs[key];
      }
    }

    return listeners;
  });

  return {
    attrs,
    listeners,
  };
}
