import { TinyEmitter } from 'tiny-emitter';
import { Ref, ref } from 'vue';

export class Acl<PermissionType extends string = string> {
  private permissions: Ref<Set<PermissionType>>;
  private listeners = new TinyEmitter();

  constructor() {
    this.permissions = ref(new Set());
  }

  setPermissions(permissions: PermissionType[]): void {
    this.permissions.value = new Set(permissions);

    this.listeners.emit('updated');
  }

  afterUpdate(callback: () => void): void {
    this.listeners.on('updated', callback);
  }

  canAll(permissions: PermissionType[]): boolean {
    return permissions.every((permission) => this.can(permission));
  }

  can(permission: PermissionType): boolean {
    return this.permissions.value.has(permission);
  }
}
