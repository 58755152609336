export function formatRange(min?: number, max?: number, options?: { fractionDigits?: number }): string {
  const fractionDigits = options?.fractionDigits ?? 2;

  if (min && max) {
    return min === max
      ? `${min.toFixed(fractionDigits)}`
      : `${min.toFixed(fractionDigits)} → ${max.toFixed(fractionDigits)}`;
  }

  if (min) {
    return `> ${min.toFixed(fractionDigits)}`;
  }

  if (max) {
    return `< ${max.toFixed(fractionDigits)}`;
  }

  return '';
}
