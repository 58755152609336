import EventHandler from '@/shared/components/state-selector/event/handlers/EventHandler';
import window from '@/shared/window';

interface WindowResizeOptions {
  onResize: () => void;
}

export default class WindowResizeHandler extends EventHandler {
  constructor(private options: WindowResizeOptions) {
    super();

    this.onResize = this.onResize.bind(this);
  }

  registerHook(): void {
    window.addEventListener('resize', this.onResize);
  }

  unregisterHook(): void {
    window.removeEventListener('resize', this.onResize);
  }

  private onResize(): void {
    this.options.onResize();
  }
}
