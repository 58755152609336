import { TinyEmitter } from 'tiny-emitter';

const emitter = new TinyEmitter();

export default {
  $on: (...args: Parameters<typeof TinyEmitter.prototype.on>) => emitter.on(...args),
  $once: (...args: Parameters<typeof TinyEmitter.prototype.once>) => emitter.once(...args),
  $off: (...args: Parameters<typeof TinyEmitter.prototype.off>) => emitter.off(...args),
  $emit: (...args: Parameters<typeof TinyEmitter.prototype.emit>) => emitter.emit(...args),
};
