import { fetchCoworkers } from '@/shared/api/offer/read';

export type CoworkerType = keyof typeof coworkers;

let coworkers: Record<string, string>;

export function isCoworkerType(code: string): code is CoworkerType {
  if (!coworkers) {
    throw 'Unable to type guard coworker code if enum is not loaded';
  }

  return !!code && !!coworkers[code];
}

export async function list(): Promise<Record<string, string>> {
  if (!coworkers) {
    coworkers = await fetchCoworkers();
  }

  return coworkers;
}
