import { Good } from '@/shared/dataProviders/offer/types';
import translator, { TranslatedMessage } from '@/shared/locale';

export function isShipmentGood(code: unknown): code is Good {
  return Object.values(Good).includes(code as Good);
}

let labels: Record<Good, TranslatedMessage>;

export function label(code: string): string | undefined {
  if (isShipmentGood(code)) {
    return loadLabels()[code];
  }

  return undefined;
}

export function list(): Record<Good, TranslatedMessage> {
  return loadLabels();
}

function loadLabels(): Record<Good, TranslatedMessage> {
  if (!labels) {
    labels = {
      [Good.Other]: translator().t('Autre'),
      [Good.Bac]: translator().t('Bac'),
      [Good.BigBag]: translator().t('BigBag'),
      [Good.Pallet100]: translator().t('Palette 100x120'),
      [Good.Pallet120]: translator().t('Palette 120x120'),
      [Good.Pallet80]: translator().t('Palette 80x120'),
      [Good.Roll]: translator().t('Roll'),
      [Good.Bulk]: translator().t('Vrac'),
    };
  }

  return labels;
}
