export type TimerListener = () => void;
export type UnregisterTimerListener = () => void;

export class AutoIntervalTimer {
  private listeners = new Set<TimerListener>();
  private timer: IntervalTimer;

  constructor(timeout: number) {
    this.timer = new IntervalTimer(() => {
      this.listeners.forEach((listener) => {
        listener();
      });
    }, timeout);
  }

  get status(): TimerStatus {
    return this.timer.status;
  }

  clearAll(): void {
    this.listeners.clear();
    this.timer.stop();
  }

  clear(listener: TimerListener): void {
    this.listeners.delete(listener);

    if (this.listeners.size === 0) {
      this.timer.stop();
    }
  }

  onTick(listener: TimerListener): UnregisterTimerListener {
    this.listeners.add(listener);

    this.timer.start();

    return () => {
      this.clear(listener);
    };
  }
}

export type TimerStatus = 'started' | 'stopped';
export type TimerTask = () => void;

export class IntervalTimer {
  private handle?: number;

  constructor(private task: TimerTask, private timeout: number) {}

  get status(): TimerStatus {
    return this.handle !== undefined ? 'started' : 'stopped';
  }

  start(): void {
    if (this.handle) {
      return;
    }

    this.handle = setInterval(() => {
      this.task();
    }, this.timeout);
  }

  stop(): void {
    if (!this.handle) {
      return;
    }

    clearInterval(this.handle);
    this.handle = undefined;
  }
}
