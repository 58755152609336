import { DateTime } from 'luxon';

import { Contact, CustomerContact } from '@/shared/dataProviders/common';
import { GoodType } from '@/shared/enums/goodType';
import { TruckType } from '@/shared/enums/truckType';

export interface FetchVehiclesFilters {
  name?: string;
  availabilityDate?: DateTime;
  location?: string;
  goodType?: GoodType;
  truckTypes?: TruckType[];
  departureCountry?: string;
  departureZones?: string;
  arrivalCountry?: string;
  arrivalZones?: string;
  weight?: string;
  length?: string;
  volume?: string;
  exchanges?: string[];
}

export interface FetchThirdPartyVehiclesFilters {
  searchId?: string;
  availabilityDate?: DateTime;
  location?: string;
  goodType?: GoodType;
  truckTypes?: TruckType[];
  departureCountry?: string;
  departureZones?: string;
  arrivalCountry?: string;
  arrivalZones?: string;
  weight?: string;
  length?: string;
  volume?: string;
  exchanges?: string[];
}

export interface VehicleInput {
  name?: string;
  goodType: GoodType;
  hazardousMaterials: boolean | null;
  truckType: TruckType;
  availabilityDate: DateTime;
  minWeight?: number;
  maxWeight?: number;
  minLength?: number;
  maxLength?: number;
  maxVolume?: number;
  palletCount?: number;
  departure: VehicleAvailabilityLocationInput;
  arrival: VehicleAvailabilityLocationInput;
  comment?: string;
  exchanges?: string[];
  saveAsTemplate?: boolean;
}

export type VehicleAvailabilityLocationInput =
  | VehicleAvailabilityLocationByCityInput
  | VehicleAvailabilityLocationByZonesInput;

export type VehicleAvailabilityLocationByCityInput = {
  type: VehicleAvailabilityLocationType.City;
  country: string;
  city: string;
  zipCode: string;
  zone: string;
  latitude: number;
  longitude: number;
  radius: number;
};

export type VehicleAvailabilityLocationByZonesInput = {
  type: VehicleAvailabilityLocationType.Zone;
  country: string;
  zones: string[];
};

export interface Vehicle {
  id: string;
  name?: string;
  hazardousMaterials: boolean | null;
  goodType: GoodType;
  truckType: TruckType;
  availabilityDate: DateTime;
  minWeight?: number;
  maxWeight?: number;
  minLength?: number;
  maxLength?: number;
  maxVolume?: number;
  palletCount?: number;
  departure: VehicleAvailabilityLocation;
  arrival: VehicleAvailabilityLocation;
  comment?: string;
  exchanges: string[];
  creationDate: DateTime;
}

export enum VehicleAvailabilityLocationType {
  City = 'city',
  Zone = 'zone',
}

export type VehicleAvailabilityLocation = VehicleAvailabilityLocationByCity | VehicleAvailabilityLocationByZones;

export type VehicleAvailabilityLocationByCity = {
  type: VehicleAvailabilityLocationType.City;
  country: string;
  city: string;
  zipCode: string;
  zone: string;
  latitude: number;
  longitude: number;
  radius: number;
};

export type VehicleAvailabilityLocationByZones = {
  type: VehicleAvailabilityLocationType.Zone;
  country: string;
  zones: Record<string, string[]>;
};

export type VehicleTemplate = Omit<Vehicle, 'availabilityDate'>;

export function isVehicle(value: Vehicle | VehicleTemplate): value is Vehicle {
  return 'availabilityDate' in value;
}

export interface ThirdPartyVehicleSearch {
  id: string;
  name: string;
  availabilityDate?: DateTime;
  goodType?: GoodType;
  truckTypes?: TruckType[];
  weight?: number;
  length?: number;
  volume?: number;
  departure?: {
    country?: string;
    zones?: Record<string, string[]>;
  };
  arrival?: {
    country?: string;
    zones?: Record<string, string[]>;
  };
  exchanges?: string[];
}

export interface ThirdPartyVehicleForList extends Vehicle {
  customerContact: CustomerContact;
}

export interface ThirdPartyVehicle extends ThirdPartyVehicleForList {
  contact: Contact;
}

export interface VehicleSearchAlert {
  id: string;
  newVehicleCount: number;
}
