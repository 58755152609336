import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

import { getUserSettings } from '@/shared/dataProviders/admin';
import { VehicleProfile } from '@/shared/enums/vehicleProfile';
import { Layout, RoutingMapOptions } from '@/shared/types';
import { get as localStorageGet, keys, set as localStorageSet } from '@/store/localStorage';
import { useAppStore } from '@/store/modules/app';
import { useSearchStore } from '@/store/modules/search';

interface Search {
  id: string;
}

const DEFAULT_LAYOUT = 'row';
const DEFAULT_MAX_SEARCH_NUMBER_ALLOWED = 10;

export const useSettingsStore = defineStore('settings', () => {
  const maxSearchNumberAllowed = ref(DEFAULT_MAX_SEARCH_NUMBER_ALLOWED);
  const searchOfferListLayouts = ref<Record<string, Layout>>(
    localStorageGet<Record<string, Layout>>(keys.searchOfferListLayouts, {})
  );
  const historyListLayout = ref<Layout>(localStorageGet<Layout>(keys.historyListLayout, DEFAULT_LAYOUT));
  const offerListLayout = ref<Layout>(localStorageGet<Layout>(keys.offerListLayout, DEFAULT_LAYOUT));
  const offerListUserIdFilter = ref(localStorageGet<string>(keys.offerListUserIdFilter, useAppStore().auth?.id ?? ''));
  const deletedOfferListLayout = ref<Layout>(localStorageGet<Layout>(keys.deletedOfferListLayout, DEFAULT_LAYOUT));
  const routingMapOptions = ref<RoutingMapOptions>(
    localStorageGet<RoutingMapOptions>(keys.routingMapOptions, {
      avoidTolls: false,
      optimization: 'time',
      vehicleProfile: VehicleProfile.AXLE_2_AND_WEIGHT_12T_OR_MORE,
    })
  );

  function onSearchUpdated(payload: { previousId: Search['id']; newId: Search['id'] }): void {
    searchOfferListLayouts.value[payload.newId] = searchOfferListLayouts.value[payload.previousId] ?? DEFAULT_LAYOUT;
    delete searchOfferListLayouts.value[payload.previousId];
  }

  function syncSearchOfferListLayouts(searches: Search[]): void {
    const layouts: Record<string, Layout> = {};

    for (const search of searches) {
      layouts[search.id] = searchOfferListLayouts.value[search.id] ?? DEFAULT_LAYOUT;
    }

    searchOfferListLayouts.value = layouts;
  }

  function setSearchOfferListLayout(payload: { searchId: string; layout: Layout }): void {
    searchOfferListLayouts.value[payload.searchId] = payload.layout;
  }

  function setHistoryListLayout(layout: Layout): void {
    historyListLayout.value = layout;
  }

  function setOfferListLayout(layout: Layout): void {
    offerListLayout.value = layout;
  }

  function setOfferListUserIdFilter(userId: string): void {
    offerListUserIdFilter.value = userId;
  }

  function setDeletedOfferListLayout(layout: Layout): void {
    deletedOfferListLayout.value = layout;
  }

  function setRoutingMapOptions(options: RoutingMapOptions): void {
    routingMapOptions.value = options;
  }

  watch(
    searchOfferListLayouts,
    () => {
      localStorageSet(keys.searchOfferListLayouts, searchOfferListLayouts.value);
    },
    { deep: true }
  );

  watch(historyListLayout, () => {
    localStorageSet(keys.historyListLayout, historyListLayout.value);
  });

  watch(offerListUserIdFilter, () => {
    localStorageSet(keys.offerListUserIdFilter, offerListUserIdFilter.value);
  });

  watch(offerListLayout, () => {
    localStorageSet(keys.offerListLayout, offerListLayout.value);
  });

  watch(deletedOfferListLayout, () => {
    localStorageSet(keys.deletedOfferListLayout, deletedOfferListLayout.value);
  });

  watch(routingMapOptions, () => {
    localStorageSet(keys.routingMapOptions, routingMapOptions.value);
  });

  const searchStore = useSearchStore();

  watch(
    () => searchStore.searches,
    () => {
      syncSearchOfferListLayouts(searchStore.searches);
    }
  );

  getUserSettings()
    .then((settings: { maxSearchNumberAllowed: number }) => {
      maxSearchNumberAllowed.value = settings.maxSearchNumberAllowed;
    })
    .catch(() => {
      maxSearchNumberAllowed.value = DEFAULT_MAX_SEARCH_NUMBER_ALLOWED;
    });

  return {
    maxSearchNumberAllowed,
    searchOfferListLayouts,
    historyListLayout,
    offerListLayout,
    offerListUserIdFilter,
    deletedOfferListLayout,
    routingMapOptions,

    onSearchUpdated,
    setSearchOfferListLayout,
    setHistoryListLayout,
    setOfferListLayout,
    setOfferListUserIdFilter,
    setDeletedOfferListLayout,
    setRoutingMapOptions,
  };
});
