import axios from 'axios';

import { useAppStore } from '@/store/modules/app';

export default function () {
  axios.defaults.headers.common['Accept-Language'] = useAppStore().locale;

  // Set a timeout of 60 seconds on HTTP requests. Prevents connections to stay open when the server does not respond.
  axios.defaults.timeout = 60000; // TODO Move to a config file
}
