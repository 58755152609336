import { App } from 'vue';

interface Value {
  [name: string]: unknown;
}

declare global {
  export interface Window {
    _mtm: Value[];
  }
}

interface Options {
  host: string;
  containerId: string;
}

export default async function install(app: App, options: Options): Promise<void> {
  window._mtm ??= [];

  window._mtm.push({
    event: 'mtm.Start',
    'mtm.startTime': new Date().getTime(),
  });

  try {
    await loadScript(`${options.host}/js/container_${options.containerId}.js`);
  } catch (error) {
    console.error(error);
  }
}

function loadScript(scriptUrl: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');

    script.async = true;
    script.defer = true;
    script.src = scriptUrl;

    script.addEventListener('load', () => resolve());
    script.addEventListener('error', reject);

    document.head.appendChild(script);
  });
}
