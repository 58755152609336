import { COUNTRY_FR } from '@/shared/enums/country';

const SEPARATOR_PATTERN = /[ ,-]/;

export function unserializeZones(zones: string, fallbackCountry = COUNTRY_FR): Record<string, string[]> {
  fallbackCountry = fallbackCountry || COUNTRY_FR; // TODO Currently required because some forms use an empty string instead of undefined

  const result: Record<string, string[]> = {};

  for (const zone of zones.toUpperCase().split(SEPARATOR_PATTERN)) {
    const [, country = fallbackCountry, state] = zone.trim().match(/^(\w{2})?(\w+)$/) ?? [];

    if (!state) {
      continue;
    }

    fallbackCountry = country;

    if (!result[country] || state === 'XX') {
      result[country] = [];
    }

    if (result[country].includes('XX')) {
      continue;
    }

    result[country].push(state);
  }

  return result;
}
