import config from '@/shared/config';
import translator, { TranslatedMessage } from '@/shared/locale';

// @todo Mettre les noms des fichiers à la place des clés
export enum Sound {
  BEEP = 'beep.mp3',
  BLOP = 'blop.mp3',
  DUCK = 'duck.mp3',
  SPECIAL = 'special.mp3',
  KLAXON = 'klaxon.mp3',
  BELL = 'bell.mp3',
  SHOT = 'shot.mp3',
  TRUMPET = 'trumpet.mp3',
}

export function isSound(code: unknown): code is Sound {
  return Object.values(Sound).includes(code as Sound);
}

let labels: Record<Sound, TranslatedMessage>;

function loadEnum(): { labels: typeof labels } {
  if (!labels) {
    labels = {
      [Sound.BEEP]: translator().t(`Bip`),
      [Sound.BLOP]: translator().t(`Blop`),
      [Sound.DUCK]: translator().t(`Canard`),
      [Sound.SPECIAL]: translator().t(`Spécial`),
      [Sound.KLAXON]: translator().t(`Klaxon`),
      [Sound.BELL]: translator().t(`Sonnette`),
      [Sound.SHOT]: translator().t(`Tir`),
      [Sound.TRUMPET]: translator().t(`Trompette`),
    };
  }

  return { labels };
}

export function list(): typeof labels {
  return loadEnum().labels;
}

export function label(code: Sound | null | string): TranslatedMessage | undefined {
  if (isSound(code)) {
    return loadEnum().labels[code];
  }

  return undefined;
}

export function file(code: Sound | null | string): string | undefined {
  if (isSound(code)) {
    // TODO Move the path elsewhere
    return `${config.urls.appBaseUrl}assets/sounds/${code}`;
  }

  return undefined;
}
