import { ValidationError } from '@/shared/dataProviders/common';
import { RestResponse } from '@/shared/dataProviders/common/RestClient';
import { mapValidationErrorField } from '@/shared/dataProviders/vehicle/b2pweb';
import { AppError } from '@/shared/types';

import { B2PWebError } from '.';

export * from './mappers';
export * from './types';

export function handleB2PWebResponse<Data>(response: RestResponse<Data, B2PWebError>): Data {
  if (response.isOk) {
    return response.data;
  }

  if (response.data.code >= 500) {
    throw new Error(response.data.message);
  }

  if (response.data.errors) {
    throw new ValidationError(
      response.data.message,
      response.data.errors.map((error) => ({
        field: mapValidationErrorField(error.field),
        code: error.code,
        message: error.message,
      }))
    );
  }

  throw new AppError(response.data.message);
}
