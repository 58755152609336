import axios from 'axios';

import { setupAuthentication } from '@/shared/api/util/interceptors/setupAuthentication';
import { setupUserLanguage } from '@/shared/api/util/interceptors/setupUserLanguage';
import config from '@/shared/config';

const instance = axios.create({
  baseURL: config.urls.searchApi,
});

setupAuthentication(instance);
setupUserLanguage(instance);

export default instance;

export const isAxiosError = axios.isAxiosError;
