import translator, { TranslatedMessage } from '@/shared/locale';

export enum LoadingDateType {
  ON = '-4',
  FROM = '-1',
  UNTIL = '-2',
  BETWEEN = '-3',
  D = '1',
  D1 = '2',
  DD1 = '3',
  DD1D2 = '4',
  D1D2 = '5',
}

let labels!: Record<LoadingDateType, TranslatedMessage>;

function loadEnum(): { labels: typeof labels } {
  if (!labels) {
    labels = {
      [LoadingDateType.ON]: translator().t(`Le`),
      [LoadingDateType.FROM]: translator().t(`Depuis le`),
      [LoadingDateType.UNTIL]: translator().t(`Jusqu'au`),
      [LoadingDateType.BETWEEN]: translator().t(`Entre`),
      [LoadingDateType.D]: translator().t(`J`),
      [LoadingDateType.D1]: translator().t(`J+1`),
      [LoadingDateType.DD1]: translator().t(`J, J+1`),
      [LoadingDateType.DD1D2]: translator().t(`J, J+1, J+2`),
      [LoadingDateType.D1D2]: translator().t(`J+1, J+2`),
    };
  }

  return { labels };
}

export function isLoadingDateType(code: unknown): code is LoadingDateType {
  return Object.values(LoadingDateType).includes(code as LoadingDateType);
}

export function list(): typeof labels {
  return loadEnum().labels;
}

export function label(code: LoadingDateType | null): TranslatedMessage | undefined {
  if (isLoadingDateType(code)) {
    return loadEnum().labels[code];
  }

  return undefined;
}
