import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

import eventBus from '@/event-bus';
import { Collection, PaginationOptions } from '@/shared/api/search';
import {
  bookmark as apiBookmark,
  getSearchConsultedOffer,
  getSearchConsultedOffers,
  getSearchOffer,
  unbookmark as apiUnbookmark,
  updateBookmark as apiUpdateBookmark,
} from '@/shared/dataProviders/search-offer';
import { ConsultedSearchOffer, ConsultedSearchOfferListFilters, SearchOfferDetail } from '@/shared/types';
import { useSearchGridStore } from '@/store/modules/search-grid';

export enum OfferTask {
  BookmarkManagement = 'bookmark-management',
}

export type DisplayedOffer = SearchOfferDetail | ConsultedSearchOffer;

export function isSearchOffer(offer: DisplayedOffer | null): offer is SearchOfferDetail {
  return !!offer && 'forbiddingLevel' in offer;
}

export function isConsultedOffer(offer: DisplayedOffer | null): offer is ConsultedSearchOffer {
  return !!offer && 'isBookmarked' in offer;
}

export const useSearchOfferStore = defineStore('search-offer', () => {
  const isDetailLoading = ref(false);
  const displayedOfferId = ref('');
  const displayedOffer = ref<DisplayedOffer | null>(null);
  const isRoutingMapDisplayed = ref(false);
  const ongoingTaskByOfferIds = ref<Record<string, OfferTask>>({});

  async function displayOffer(offerId: string, searchId = '') {
    if (displayedOfferId.value === offerId) {
      return;
    }

    isDetailLoading.value = true;
    displayedOfferId.value = offerId;

    let offer: DisplayedOffer | null = null;

    try {
      offer = (await getSearchOffer(offerId, searchId)) ?? null;
    } finally {
      displayedOffer.value = offer;
      isDetailLoading.value = false;
    }
  }

  async function displayConsultedOffer(offerId: string) {
    if (displayedOfferId.value === offerId) {
      return;
    }

    isDetailLoading.value = true;
    displayedOfferId.value = offerId;

    let offer: DisplayedOffer | null = null;

    try {
      offer = (await getSearchConsultedOffer(offerId)) ?? null;
    } finally {
      displayedOffer.value = offer;
      isDetailLoading.value = false;
    }
  }

  function hideOffer() {
    displayedOfferId.value = '';
    displayedOffer.value = null;
  }

  async function fetchConsultedOffers(
    filters: ConsultedSearchOfferListFilters & PaginationOptions
  ): Promise<Collection<ConsultedSearchOffer>> {
    return await getSearchConsultedOffers(filters);
  }

  async function bookmark({ id, data = {} }: { id: string; data?: { price?: number; notes?: string } }): Promise<void> {
    try {
      addOngoingTasks({ ids: [id], type: OfferTask.BookmarkManagement });

      await apiBookmark(id, data);
    } finally {
      removeOngoingTasks({ ids: [id] });
    }

    eventBus.$emit('search.offer.bookmarked', { offerIds: [id] });

    if (isConsultedOffer(displayedOffer.value) && displayedOffer.value.id === id) {
      displayedOffer.value = {
        ...displayedOffer.value,
        isBookmarked: true,
        userNotes: data.notes ?? null,
        userPrice: data.price !== undefined ? { value: data.price, currency: 'EUR' } : null,
      };
    }
  }

  async function unbookmark(ids: string | string[]): Promise<void> {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }

    try {
      addOngoingTasks({ ids, type: OfferTask.BookmarkManagement });

      await apiUnbookmark(ids);
    } finally {
      removeOngoingTasks({ ids });
    }

    eventBus.$emit('search.offer.unbookmarked', { offerIds: ids });

    if (isConsultedOffer(displayedOffer.value) && ids.includes(displayedOfferId.value)) {
      displayedOffer.value = {
        ...displayedOffer.value,
        isBookmarked: false,
        userPrice: null,
        userNotes: null,
      };
    }
  }

  async function updateBookmark({
    id,
    data,
  }: {
    id: string;
    data: { price?: number; notes?: string };
  }): Promise<ConsultedSearchOffer> {
    const consultedOffer = await apiUpdateBookmark(id, data);

    if (isConsultedOffer(displayedOffer.value) && displayedOfferId.value === id) {
      displayedOffer.value = consultedOffer;
    }

    return consultedOffer;
  }

  function addOngoingTasks({ ids, type }: { ids: string[]; type: OfferTask }) {
    for (const id of ids) {
      ongoingTaskByOfferIds.value[id] = type;
    }
  }

  function removeOngoingTasks({ ids }: { ids: string[] }) {
    for (const id of ids) {
      delete ongoingTaskByOfferIds.value[id];
    }
  }

  function showRoutingMap(): void {
    if (!displayedOfferId.value) {
      return;
    }

    isRoutingMapDisplayed.value = true;
  }

  function hideRoutingMap(): void {
    isRoutingMapDisplayed.value = false;
  }

  watch(displayedOfferId, () => {
    hideRoutingMap();
  });

  const searchGridStore = useSearchGridStore();

  watch(
    () => searchGridStore.activeSearchIds,
    () => {
      hideRoutingMap();
    }
  );

  return {
    displayedOfferId,
    displayedOffer,
    isDetailLoading,
    displayOffer,
    displayConsultedOffer,
    hideOffer,
    fetchConsultedOffers,
    bookmark,
    unbookmark,
    updateBookmark,

    ongoingTaskByOfferIds,

    isRoutingMapDisplayed,
    showRoutingMap,
    hideRoutingMap,
  };
});
