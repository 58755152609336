import * as Popper from '@popperjs/core';
import { pickBy } from 'lodash-es';
import tippy, { DefaultProps, Instance, PopperElement, Props } from 'tippy.js';
import { App, Directive, DirectiveBinding } from 'vue';

function isPopperElement(el: HTMLElement): el is PopperElement {
  return '_tippy' in el;
}

const visibleTooltips = new Set<Instance>();

const positions: Popper.Placement[] = [
  'top',
  'top-start',
  'top-end',
  'right',
  'right-start',
  'right-end',
  'bottom',
  'bottom-start',
  'bottom-end',
  'left',
  'left-start',
  'left-end',
  'auto',
  'auto-start',
  'auto-end',
];

function getPlacement(value: { placement?: Popper.Placement }, modifiers: DirectiveBinding['modifiers']) {
  let placement = value.placement;

  if (modifiers) {
    positions.forEach((position) => {
      if (modifiers[position]) {
        placement = position;
      }
    });
  }

  return placement;
}

function composeTippyProps(binding: DirectiveBinding): Partial<Props> {
  const placement = getPlacement(binding.value, binding.modifiers) || 'top';

  return typeof binding.value === 'string'
    ? { content: binding.value, placement, onShow, onHide }
    : { ...pickBy(binding.value, (value) => value !== undefined), placement, onShow, onHide };
}

function onShow(instance: Instance): void {
  visibleTooltips.add(instance);
}

function onHide(instance: Instance): void {
  visibleTooltips.delete(instance);
}

function hideTooltip(tooltip: Instance): void {
  const originalDuration = tooltip.props.duration;

  tooltip.setProps({ duration: 0 });
  tooltip.hide();

  if (!tooltip.state.isDestroyed) {
    tooltip.setProps({ duration: originalDuration });
  }
}

export const Tooltip: Directive = {
  beforeMount(el, binding) {
    const isEnabled = !!binding.value;

    if (isEnabled) {
      tippy(el, composeTippyProps(binding));
    }
  },

  updated(el, binding) {
    const isEnabled = !!binding.value;

    if (isPopperElement(el) && el._tippy) {
      if (isEnabled) {
        el._tippy.setProps(composeTippyProps(binding));
      } else {
        el._tippy.destroy();
      }
    } else if (isEnabled) {
      tippy(el, composeTippyProps(binding));
    }
  },

  unmounted(el) {
    if (isPopperElement(el) && el._tippy) {
      el._tippy.destroy();
    }
  },
};

export const TooltipPlugin = {
  install(app: App, options: Partial<DefaultProps> = {}): void {
    tippy.setDefaultProps(options);

    app.directive('tooltip', Tooltip);

    window.addEventListener(
      'scroll',
      () => {
        visibleTooltips.forEach((tooltip) => hideTooltip(tooltip));
      },
      { capture: true, passive: true }
    );
  },
};
