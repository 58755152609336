import { clamp } from 'lodash-es';
import { DateTime } from 'luxon';

export function composeDate(date: string, base: DateTime = DateTime.local()): DateTime | null {
  const parsed = /(?<day>\d+)(?:\/(?<month>\d+))?(?:\/(?<year>\d+))?/.exec(date.trim());

  if (!parsed?.groups) {
    return null;
  }

  let formatted = base;

  if (parsed.groups.year) {
    let stringifiedYear = formatted.year.toString(10);

    if (!stringifiedYear.startsWith(parsed.groups.year)) {
      stringifiedYear = parsed.groups.year.padEnd(4, '0');
    }

    formatted = formatted.set({
      year: parseInt(stringifiedYear, 10),
    });
  }

  if (parsed.groups.month) {
    formatted = formatted.set({
      month: clamp(parseInt(parsed.groups.month, 10) || formatted.month, 1, 12),
    });
  }

  if (parsed.groups.day) {
    formatted = formatted.set({
      day: clamp(parseInt(parsed.groups.day, 10) || formatted.day, 1, formatted.daysInMonth),
    });
  }

  // In some cases, when the date is earlier than the base date, we must shift the date into the future. Otherwise, the date will be an invalid prediction
  if (formatted < base) {
    // Only if a partial year was given and the predicted year is the same as the base year
    if (parsed.groups.year) {
      formatted = formatted.plus({
        years: parsed.groups.year.length < 4 && formatted.year === base.year ? 1 : 0,
      });
    }
    // Always if a month was given
    else if (parsed.groups.month) {
      formatted = formatted.plus({ years: 1 });
    }
    // Always if a day was given
    else {
      formatted = formatted.plus({ months: 1 });
    }
  }

  return formatted.startOf('day');
}
