import { Point } from '@/shared/components/state-selector/utils/math';

export interface MapDefinition {
  boundary: Boundary;
  countries: CountryDefinition[];
}

export interface CountryDefinition {
  id: string;
  neighbors: string[];
  colorset: number;
  boundary: Boundary;
  path: string;
  groups: GroupDefinition[];
}

export interface GroupDefinition {
  id: string;
  // label: string;
  shade: number;
  boundary: Boundary;
  path: string;
  states: StateDefinition[];
}

export interface StateDefinition {
  id: string;
  label: string;
  textPosition: Point;
  boundary: Boundary;
  path: string;
}

export interface Boundary {
  x: number;
  y: number;
  width: number;
  height: number;
}

export class MapDefinitionLoader {
  constructor(private url: string) {}

  load(): Promise<MapDefinition> {
    return fetch(this.url.replace('{id}', 'europe')).then((response) => response.json());
  }

  loadCountry(countryId: string): Promise<CountryDefinition> {
    return fetch(this.url.replace('{id}', countryId)).then((response) => response.json());
  }
}
